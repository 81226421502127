.path-frontpage {
  .highlighted{
    position: relative;
    z-index: 1;
    .administra-gestor-botonera-superior{
      float: right;
      position: relative;
      top: 20px;
      height: 0px;
      right: 140px;
      .btn-info{
        margin-left: 20px;
        color: #fff;
      }
    }
  }
}
.administra-gestor-botonera-superior{
  float: right;
  position: relative;
  top: -70px;
  height: 0px;
  right: 140px;
  .btn-info{
    margin-left: 20px;
    color: #fff;
  }
}

header {
  background-color: #007bc4;
}


/* TOP NAVBAR */
.path-frontpage {
  header {
    .top-navbar-logos {
      margin: 0px 0px 50px 0px;
    }
  }
}
header {
  .top-navbar-logos {
    position: relative;
    display: flex;
    padding: 20px 0px 20px 0px;
    margin: 0px 0px 20px 0px;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid #fff;
  }
  .top-navbar-logos .logo {
    display: inline-block;
    width: 140px;
    float: none !important;
    margin-right: 0px;
  }
  .top-navbar-logos .logo img {
    height: 48px;
  }
}

// Botones
.navbar-toggle {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  float: none;
  padding: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: transparent;
  outline: 0px;
  border: 0px;
}
.navbar-toggle:focus,
.navbar-search:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;
}
.navbar-search {
  right: 42px;
  width: 30px;
  background: transparent;
  outline: 0px;
  border: 0px;
}
.navbar-search img {
  width: 100%;
  max-width: 100%;
  display: inline-block;
}
.navbar-close {
  top: -3px;
  transform: none;
  background: transparent;
  outline: 0px;
  border: 0px;
}

/* NAVBAR */
header {
  .navbar-header  {
    padding: 25px 30px 25px 30px !important;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  #main-menu-collapse {
    background-color: #002B4A;
    width: 100%;
    max-width: 460px;
    height: 100%;
    margin-top: 0px;
    height: 100vh !important;
    position: fixed !important;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -webkit-transition: all 0.35s !important;
    -o-transition: all 0.35s !important;
    transition: all 0.35s !important;
    -moz-transition: all 0.35s !important;
    -ms-transition: all 0.35s !important;
  }
  #main-menu-collapse.show {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    -moz-transform: translateX(0);
    box-shadow: -8px 12px 15px rgba(0, 0, 0, .30);
  }
}


// Menu Superior
header {
  .navbar-header .menu-superior,
  .navbar-header .menu-secundario {
    position: relative;
    display: block;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 25px;
  }
  .navbar-header .menu-superior .top-navbar-idioma {
    display: block;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span {
    display: inline-block;
    padding: 0px;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span+span:before {
    content: "|";
    padding: 0px 8px 0px 8px;
    font-size: .7rem;
    color: #fff;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span+span:before {
    content: "|";
    padding: 0px 8px 0px 8px;
    font-size: .7rem;
    color: #fff;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span a {
    font-size: .7rem;
    padding: 4px 8px 2px 8px;
    line-height: 20px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span.is-active a,
  .navbar-header .menu-superior .top-navbar-idioma nav span a:hover {
    background: #007bc4;
    font-weight: 700;
    font-size: .65rem;
  }
  .navbar-header .menu-superior .top-navbar-idioma nav span a:hover,
  .navbar-header .menu-superior .top-navbar-idioma nav span a:focus {
    text-decoration: none;
  }
}

/* BUSCADOR */
header {
  .block-search{
    .container-inline{
      >div{
        width: 100%;
      }
      form.search-form{
        padding:5px 0 !important;
        position: relative;
      }
      .js-form-type-search{
        flex-basis: 100%;
        margin-bottom: 0 !important;
        padding-left:5px;
        padding-right: 5px;
        input{
          background-color: transparent;
          color:#FFF;
          padding-left:30px;
          border:none;
          box-shadow: none;
        }
      }
      div[data-drupal-selector="edit-actions"]{
        position: absolute;
        top: 0;
        margin:0 !important;
        padding-left:5px;
        padding-right: 5px;
        button{
          width: 20px;
          height: 35px;
          background-color: transparent;
          border-color:transparent;
          background-image: url("../images/logos/ico_buscador.svg");
          background-repeat: no-repeat;
          background-position: 0px 10px;
          background-size: 20px;
        }
      }
    }
  }
}

/* Menú */
header {
  .navbar-header nav {
    display: block;
    width: 100%;
    order: 1;
  }
  .navbar-header nav ul.nav {
    margin: 0px;
    display: block;
    width: 100%;
    float: none;
  }
  .navbar-header .nav > li {
    padding: 10px 0px;
    display: block;
    width: 100%;
    float: none;
    border-bottom: 1px solid #fff !important;
  }
  .navbar-header .nav > li:last-child {
    border-bottom: none!important;
  }
  .navbar-header .nav > li > a {
    position: relative;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 6px 13px 4px 0px;
    color: #fff;
    margin: 0px;
    border: 0px;
    transition: .3s ease;
  }
  .navbar-header .nav > li > a:hover,
  .navbar-header .nav > li > a:focus  {
    // background: #007bc4;
    color: #BFDEF0;
  }
  .navbar-header .nav li .dropdown-toggle::after {
      margin-left: 0px;
      vertical-align: inherit;
      border: 0px;
      position: absolute;
      display: inline-block;
      border: 0px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 10px;
      background-image: url('../images/icons/flechamenusupinv.svg');
      background-size: 18px 10px;
      background-repeat: no-repeat;
      content: "";
    }
    .navbar-header .nav li.show .dropdown-toggle::after {
     transform: rotate(180deg);
     top: 40%;
   }
   .nav>li>a:hover, .nav>li>a:focus {
     background-color: transparent;
     border: 0px !important;
   }
   /* Dropdown */
   .navbar-header .nav > li .dropdown-menu {
       display: none;
       background-color: transparent;
       float:none;
       box-shadow: none;
       position: relative !important;
       left: 0px;
       padding: 12px 0px 12px 24px;
       border: 0px !important;
       inset: none !important;
       margin: 0px;
       transform: none !important;
   }
   .navbar-header .nav > li.show .dropdown-menu {
       display: block;
   }
   .navbar-header .nav > li .dropdown-menu li {
       width: 100% !important;
       float: none !important;
       padding: 0px;
       border-bottom: 0px;
       border-left: 0px;
       border-right: 0px;
   }
   .navbar-header .nav .dropdown-menu li:hover,
   .navbar-header .nav .dropdown-menu li:focus {
     background-color: transparent;
   }
   .navbar-header .nav .dropdown-menu li a {
       background: transparent;
       display:block;
       border: 0px !important;
       padding: 6px 10px 4px 10px !important;
       font-size: .9rem !important;
       color: #fff !important;
       white-space: pre-wrap;
       text-decoration: none;
       transition: .3s ease;
   }
   .navbar-header .nav .dropdown-menu li a:hover,
   .navbar-header .nav .dropdown-menu li a:focus {
     // background: #007bc4;
     color: #BFDEF0 !important;
   }

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent!important;
}


   .navbar-header .menu--menu-secundario{
    margin-top: 30px;
    border-top: 1px dashed #fff;
    .nav {
      margin-top: 20px;
      li{
        border-bottom:none!important;
        background: transparent;
        padding-bottom: 0px;
        a{
          display: block;
          border: 0px !important;
          padding: 6px 10px 4px 0px !important;
          font-size: .9rem !important;
          color: #fff !important;
          white-space: pre-wrap;
          text-decoration: none;
          -webkit-transition: .3s ease;
          -o-transition: .3s ease;
          transition: .3s ease;
        }
      }
    }
   }
}

/* Search */
header {
  .navbar-header .search-block-form {
    display: inline-block;
    padding: 0px 0px;
    order: 0;
    width: 100%;
  }
  .navbar-header .search-block-form form,
  .navbar-header .search-block-form form .form-item,
  .navbar-header .search-block-form form .form-item .input-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
  .navbar-header .search-block-form form,
  .navbar-header .search-block-form form .form-item,
  .navbar-header .search-block-form form .form-item .input-group {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
  }
  .navbar-header .search-block-form form .form-item .input-group label {
    display: none;
  }
  .navbar-header .search-block-form form .form-item .input-group {
    position: relative;
  }
  .navbar-header .search-block-form form .form-item .input-group input {
    height: auto;
    float: none;
    padding: 8px 12px 8px 35px;
    border-radius: 8px;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    border: 0px solid transparent;
    box-shadow: none !important;
    transition: none !important
  }
  .navbar-header .search-block-form form .form-item .input-group input::placeholder,
  .navbar-header .search-block-form form .form-item .input-group input:-ms-input-placeholder,
  .navbar-header .search-block-form form .form-item .input-group input::-ms-input-placeholder {
    font-size: 1rem !important;
    color: #fff !important;
    opacity: 1 !important;
  }
  .navbar-header .search-block-form form .form-item .input-group input:focus {
    box-shadow: none !important;
  }
  .navbar-header .search-block-form form .form-item .input-group .input-group-btn {
    width: auto;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 50%;
    z-index: 5;
    transform: translateY(-50%);
  }
  .navbar-header .search-block-form form .form-item .input-group .input-group-btn button {
    box-shadow: none !important;
    transition: none !important;
    background-color: inherit;
    border: 0px;
    outline: 0px;
    padding: 6px 10px 6px 4px;
    border-radius: 14px 0px 0px 14px;
  }
  .navbar-header .search-block-form form .form-item .input-group .input-group-btn button span{
    color: #fff;
    font-size: 1rem;
  }
  .navbar-header .search-block-form form .form-item .input-group .input-group-btn button span:before {
    display: inline-block;
    width: 20px;
    content: url('../images/ico_buscador.svg');
  }
}


/* xs */
@media (max-width: 767.98px) {
  /* TOP NAVBAR */
  header .top-navbar-logos {
    position: relative;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0px 10px 0px;
  }

  header .top-navbar-logos .logo {
    width: 160px;
    margin: 10px 0px;
  }


}

/* sm */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* xl */
@media (min-width: 1200px) {
}

//
//OCULTAMOS INGLÉS EN MENÚ
.language-switcher-language-url {
  span.en{
    display: none !important;
  }
 }
