nav .breadcrumb li a {
    font-weight: bold;
}
header .top-navbar-logos {
    height: 112px;
    padding: 0px;
}

.footer .col-xs-12.col-md-2:last-child {
    background-image: url(/sites/default/files/Logo_xacobeo_2021.svg) !important;
}
footer .footer {
    padding: 24px 0;
}
footer .footer .logo-xunta a {
    width: 127px;
    height: 36px;
    background-size: 127px;
}
footer .footer .links-xunta ul li {
    margin-right: 3px!important;
    font-size:10px!important;
}
footer .footer .col-xs-12{
    height: 36px;
}
.footer .col-xs-12.col-md-2:last-child {
    height: 36px!important;
    background-size: 153px;
    width: 16.6%;
}

.footer .col-xs-12.col-md-2:last-child a{
    height: 36px!important;
    width: 190px!important;
    background-size: 190px!important;
}
@media (max-width: 991px){
  header .top-navbar-logos {
    height: 72px!important;
    padding: 0px;
  }
  header .top-navbar-logos .logo img {
    height: 36px!important;
  }
 footer .footer{
    height: 190px;
    padding: 14px 0;
 }

  footer .footer .links-xunta ul li a {
    font-size: 12px!important;
 }

 footer .footer .col-xs-12 {
    height: 28px;
  }


  footer .footer .logo-xunta a {
    width: 100px;
    height: 28px;
    background-size: 100px;
  }
  footer .div-logo-galicia{
    display: block;
    position: absolute;
    right: 15px;
    top: 0px px;
    width: 150px!important;
    height: 28px!important;
     background: url(/sites/default/files/Logo_xacobeo_2021.svg) no-repeat!important;
     background-size: 140px!important;
  }
  .footer .col-xs-12.col-md-2:last-child{
    display:none!important;
  }
  footer .footer .links-xunta ul li span {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer .col-md-2 {
    width: 100%!important;
  }
}

footer .footer .links-xunta p {
    font-size: 10px;
}


@media (max-width: 991px){


  footer .footer .links-xunta {
    margin-top: 14px;
  }

  footer .footer .links-xunta p {
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
  }
  footer .footer .links-xunta ul {
    display: block;
    text-align: left;
    list-style-type: none !important;
    padding-left: 0;
    margin: 0;
  }
  footer .footer .links-xunta ul li {
    display: block;
    margin-top: 6px;
    float: none;
    text-align: left;
    font-size: 12px;
  }

}

.block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-field-fecha- {
    color: #4c6b84!important;
}

footer .footer .links-xunta ul li a {
    font-size: 10px!important;

}

@media (max-width: 768px){
  header#header {
    position: fixed;
    width: 100%;
    top: auto;
    left: 0px;
    z-index: 100000;
    background: #007bc4;
    height: 72px;
  }
  header#header .top-navbar-logos {
    margin-bottom: -1px!important;
  }
 main.main-content {
    margin-top: 72px!important;
 }
 .cabecera-portada {
    padding-top: 20px;
  }
  footer .footer .links-xunta ul li a {
    font-size: 12px!important;
}
}