/* VARIABLES */

// colors
$primary-shade: rgb(255, 78, 46);
$primary-light: lighten($primary-shade, 37%);
$primary-dark: darken($primary-shade, 12%);
$accent-shade: #0079C0;
$accent-light: lighten($accent-shade, 37%);
$accent-dark: darken($accent-shade, 12%);
// Reassign color vars to semantic color scheme
$red: #dc3545;
$yellow: #ffc107;
$green: #28a745;
$cyan: #17a2b8;
$white: #fff;
$gray-100: #f8f9fa;
$gray-800: #343a40;
$theme-colors: ( primary: $accent-shade, secondary: $primary-shade, success: $green, info: $cyan, warning: $yellow, danger: $red, light: $gray-100, dark: $gray-800);
$brand-primary: $accent-shade;
//$brand-success: $green;
//$brand-info: $teal;
//$brand-warning: $orange;
//$brand-danger: $red;
$brand-inverse: $primary-shade;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white;
$body-color: $gray-800;
$inverse-bg: $primary-shade;
$inverse-color: $white;
// Links
//
// Style anchor elements.
$link-color: $accent-shade;
$link-decoration: none;
$link-hover-color: $accent-dark;
$link-hover-decoration: underline;
// Comments
$comment-spacer-x: 1.25em;
$comment-spacer-y: 1.25em;
// Responsive font sizes
$enable-responsive-font-sizes: true;
$enable-shadows: false;
$enable-gradients: false;


$main: #010917;
$azul_xunta: #007bc4;
$image_selected_border: #007bc4;
$dark_blue: #4C6B80;
$boton_main: #007bc4;
$boton_hover: #006EBB;
$boton_click: #0061B2;
$background: #F5F9FF;
$placeholder: #A7ADB6;
