/* Footer */


.prefooter {
  background-color:#FAFAF9;
  padding-top: 40px;
  margin-top: 40px;
  min-height: 170px;
  color:#010917;
  .field--name-field-logos{
    float: right;
    .field__item{
      float: right;
      margin-right: 20px;
      img{
        width: 150px;
        object-fit: contain;
        height: 75px;
      }
    }
  }

  h2{
      font-size:18px;
      margin-bottom: 15px;
      display: block;
      font-weight: bold;
      padding-left: 0 !important;
  }
  .field--name-body{
    line-height: 22px;
    p {
      margin-bottom: 5px;
    }
  }
  .field--name-field-redes-sociales-footer{
    margin-top: 28px;
    .field__item{
      float: left;
      width: 30px;
      a{
        color:#FAFAF9!important;
        i{
          color:#010917;
          &:hover{
            color: #4C6B80;
          }
        }
      }
    }

  }
}
footer .footer {
  background-color: $azul_xunta;
  margin-top: 0 !important;
  font-size: 11px;
  width: 100%;
  padding: 20px 0;
  left: 0;
  bottom: 0;
  width: 100%;
}
footer .footer .titulo {
  background-color: #CAEEF2;
  color: #00587A;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  background-image: url(../images/ico_title_small_oscuro.png);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-size: contain;
}


footer .footer .logo-xunta a {
  display: block;
  width: 117px;
  height: 33px;
  background: url(../images/Logo_xunta_2021.svg) no-repeat;
  background-size: 110px;
}

footer .footer .logo-xunta a span {
  display: none;
}
footer .footer .logocc {
    height: 15px;
}
footer .footer .menu--menu-footer{
  display:inline-block
}
footer .footer .links-xunta p {
  margin-bottom: 0;
  font-size: 11px;
  color: #fff;
}

footer .footer .links-xunta ul {
  list-style-type: none !important;
  padding-left: 0;
  margin: 0;
}

footer .footer .links-xunta ul li {
  float: left;
  margin-right: 3px;
  font-size: 11px;
  color: #fff;
}
footer .footer .links-xunta ul li span {
  font-size: 11px;
  color: #fff;
}
footer .footer .links-xunta ul li a {
  color: #fff;
  font-size: 11px;
  text-decoration: none;
}
footer.footer .links-xunta ul li:last-child {
  display: none;
}
footer .footer .links-xunta ul li a:hover {
  text-decoration: underline;
}
/*
footer.footer .logo-galicia {
  display: block;
  float: right;
  width: 140px;
  height: 35px;
  background: url(../images/Logo_xacobeo_2021.svg) no-repeat;
  background-size: 140px;
}
footer.footer .logo-galicia span {
  display: none;
}*/
footer .footer .container-fluid .col-xs-12:last-child{
  background-position: 15px 0px;
  a{
    display: block;
    width: 100%;
    height: 100%;
  }
}


@media (max-width:768px) {
  footer .footer {
    min-height: 120px;
    margin-bottom: 0;
  }

  footer .footer .links-xunta {
    display: block;
    margin-top: 20px;
    top: 0;
    color: #fff;
  }

  footer .footer #RSSsuscripcion {
    clear: both;
    float: left;
    margin-top: 20px;
  }

  footer .footer .logo-galicia {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-right: 0;
  }
}

@media (max-width:991px) {

  footer .footer #RSSsuscripcion {
    margin-top: -15px;
  }

  footer .footer .logo-xunta {
    margin-bottom: 25px;
  }

  footer .footer .logo-galicia {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-right: 0;
  }
}

@media (min-width:993px) {
  a.div-logo-galicia {
    display: none !important;
  }
}

@media (max-width:992px) {

  footer .footer, footer .footer .logo-xunta {
    margin-bottom: 0;
  }

  footer .footer .logo-galicia {
    display: none;
  }

  footer .footer a.div-logo-galicia {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 30px;
    margin-right: 15px;
    background: url(../images/logo-galicia.svg) no-repeat;
    background-size: 90px;
  }

  footer .footer a.div-logo-galicia span {
    display: none;
  }

  footer .footer .links-xunta {
    display: block;
    margin-top: 20px;
    top: 0;
    color: #fff;
  }
}



/* xs */
@media (max-width: 767.98px) {
  .prefooter {
    h2{
      text-align: center;
    }
    .field--name-body {
      text-align: center;
      strong {
        font-size: 18px;
        margin-bottom: 15px;
        display: block;
      }
      p {
        font-size: 16px;
      }
    }
    .field--name-field-redes-sociales-footer {
      display: flex;
      justify-content: center;
      width: 100%;
      overflow: hidden;
      .field__item {
        float: none;
        width: 30px;
      }
    }
    .field--name-field-logos {
      display: flex;
      justify-content: center;
      width: 100%;
      .field__item {
        float: none;
        margin-right: 20px;
      }
    }
  }
}



/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  .prefooter {
    .field--name-body {
      strong {
        font-size: 18px;
        margin-bottom: 15px;
        display: block;
      }
      p {
        font-size: 16px;
      }
    }
    .field--name-field-redes-sociales-footer {
      .field__item {

      }
    }
    .field--name-field-logos {

      .field__item {

      }
    }
  }
}

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .prefooter {
    .field--name-body {
      margin-bottom: 20px;
      strong {
        font-size: 18px;
        margin-bottom: 15px;
        display: block;
      }
      p {
        font-size: 16px;
      }
    }
    .field--name-field-redes-sociales-footer {
      .field__item {

      }
    }
    .field--name-field-logos {

      .field__item {

      }
    }
  }
}
