.path-frontpage{
  #main {
    background: #007bc4;
    color: #fff;
    height: 400px;
    margin-bottom: 100px;

    /* Slider Home */
    .block-slider-portada-block {
      .slick-slider .slick-prev,
      .slick-slider .slick-next {
          top: -5px !important;
      }
      .slick-slider .slick-prev,
      .slider-page .slick-prev {
          left: -65px;
      }
      .slick-slider .slick-prev:before,
      .slider-page .slick-prev:before {
          color: #fff;
      }

      .slick-slider .slick-next,
      .slider-page .slick-next {
        right: 40px;
      }
      .slick-slider .slick-next:before,
      .slider-page .slick-next:before {
        color: #fff;
      }


      .front-title h2 {
        font-size: 2.45rem;
        font-weight: 600;
      }
      .front-subtitle {
        font-size: 1.15rem;
        color: #fff;
        font-weight: 400;
        strong {
          font-weight: 600;
          color: #fff;
        }
        ul {
          display: flex;
          flex-wrap:wrap;
          align-items: center;
          padding: 0px;
          li {
            display: inline-block;
            width: 50%;
            margin: 0px 0px 10px 0px;
            padding: 0px 0px 0px 20px;
            position: relative;
            &:before {
              content:'';
              position: absolute;
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              border: 2px solid #007BC4;
              background: #fff;
            }
          }
        }
      }
    }

    a{
      color: #fff;
    }
    #tabsContent{
      .front-ligazon{
        a{
          background-color: #fff;
          color: #007bc4;
          padding: 8px 18px;
          text-decoration: none;
          border-radius: 4px;
          font-size: 16px;
        }
        a:hover{
          background-color: #007bc4;
          color:#FFF;
          border:1px solid #FFF;
        }
      }
    }
    .imaxeportada {
      background-size: contain;
      background-repeat: no-repeat;
      width: 100%;
      height: 455px;
      position: relative;
      .ligazons-portada {
        position: absolute;
        bottom: 52px;
        right: -9%;
        width: 110%;
        .nav-tabs {
          border-bottom: none;
          height: 200px;
        }
        .nav-link.active{
          background-color: transparent;
          border: none;

        }
        .nav-link{
          position: relative;
          overflow: visible;
            transition: none;
            padding: 1rem .5rem 2rem .5rem;
        }
        .nav-link:hover,.nav-item:focus, .nav-link:focus {
          border: none;
          outline: none;
          img {
            transform: scale(1.05);
            opacity: 1;
          }
        }

        img {
          width: 100%;
          opacity: .8;
          object-fit: cover;
          object-position: center;
          height: 120px;
          border-radius: 10px;
          transition: .3s ease;
        }
        p {
          position: absolute;
          //bottom: 0px;
          top:135px;
          left: 10px;
          margin-top: 10px;
          margin-bottom: 0rem;
        }
      }
    }
  }
  .view-portada-destacados{
    .view-header{
      .destacados-portada {
          font-size: 1.9rem;
          color: $dark_blue;
          width: 100%;
          display: block;
          margin-bottom: 30px;
          line-height: 30px;
          strong {
            color:#010917;
          }
      }
    }
    .fila{
      height: 240px;
      .views-field-field-imaxe-destacados{
        img{
          width: 100%;
          object-fit: cover;
          height: 220px;
        }
      }
      .contenedor-destacados{
        height: 220px;
      }
      .contenedor-destacados:hover .views-field-title,
      .contenedor-destacados:focus .views-field-title,
      .contenedor-destacados:hover img,
      .contenedor-destacados:focus img{
        transform: scale(1.01);
        opacity: 1;
      }

      .views-field-title{
        position: relative;
        margin-bottom: -70px;
        display: block;
        height: 100px;
        bottom: 100px;
        background: linear-gradient(to bottom, transparent, #010917);
        a{
          position: absolute;
          bottom: 20px;
          top: inherit;
          color: #fff;
          text-decoration: none;
          font-size: 1.2rem;
          font-weight: bold;
          left: 30px;
          right: 30px;
        }
      }
    }
    .more-link{
      float: right;
      a{
        color: #010917;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
      a:after{
        font-family: 'Font Awesome 5 Free';
        content: "\f061";
        font-size: .8rem;
        font-weight: bold;
        color: #303333;
        margin-left: 10px;
        text-decoration: none;
        display: inline-block;
      }
    }
  }
}


/* xs */
@media (max-width: 767.98px) {
  .path-frontpage #main #tabsContent .front-ligazon a {
    font-size: .9rem;
  }
  .path-frontpage{
    #main {
      //height: 500px;
      .block-slider-portada-block {
        .col-5 {
          width: 100%;
          height: 125px;
          .front-title h2 {
            font-size: 1.8rem;
          }
        }
        .col-7 {
          width: 100%;
          .imaxeportada  {
            background-position: center;
            height: 350px;
            position: absolute;
            margin-top: 0px;
            left: 0px;
            bottom: -340px;
            .ligazons-portada {
                position: absolute;
                bottom: 70px;
                right: 0px;
                width: 100%;
                .nav-tabs {
                  height: 180px;
                }
                p{
                  top:115px;
                }
                img {
                  height: 100px;
              }
            }
          }
        }
      }
    }
    .main-content {
      margin-bottom: 60px;
      .view-portada-destacados {
        .view-header {
          .destacados-portada {
            font-size: 1.7rem;
          }
        }
        .fila {
          .views-field-title {
            a {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }
}

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  .path-frontpage{
    #main {
      .block-slider-portada-block {
        .col-5 {
          width: 100%;
          .front-title h2 {
            font-size: 1.8rem;
          }
        }
        .col-7 {
          width: 100%;
          .imaxeportada  {
            background-position: center;
            height: 350px;
            .ligazons-portada {
                position: absolute;
                bottom: 80px;
                right: 0px;
                width: 100%;
                .nav-tabs {
                  height: 180px;
                }
                img {
                  height: 100px;
              }
              p{
                top:115px;
              }
            }
          }
        }
      }
    }
    .main-content {
      margin-bottom: 60px;
      .view-portada-destacados {
        .view-header {
          .destacados-portada {
            font-size: 1.7rem;
          }
        }
        .fila {
          .views-field-title {
            a {
              font-size: 1rem;

            }
          }
        }
      }
    }
  }
}


/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .path-frontpage{
    #main {
      .block-slider-portada-block {
        .col-5 {
          .front-title h2 {
            font-size: 1.9rem;
          }
        }
        .col-7 {
          .imaxeportada  {
            background-position: center;
            height: 565px;
            .ligazons-portada {
                position: absolute;
                bottom: 160px;
                right: 0px;
                width: 100%;
            }
          }
        }
      }
    }
    .main-content {
      margin-bottom: 60px;
      .view-portada-destacados {
        .view-header {
          .destacados-portada {
            font-size: 1.8rem;
          }
        }
        .fila {
          .views-field-title {
            a {
              font-size: 1.1rem;

            }
          }
        }
      }
    }
  }
}


/* XL */
@media (min-width: 1200px) and (max-width: 1400px) {
  .path-frontpage{
    #main {
      /* Slider Home */
      .block-slider-portada-block {
        .front-title h2 {
          font-size: 2.45rem;
        }
        .front-subtitle {
          font-size: 1rem;
        }
      }
      .block-slider-portada-block {
        .col-5 {
          .front-title h2 {
          }
        }
        .col-7 {
          .imaxeportada  {
            background-position: center;
                height: 500px;
            .ligazons-portada {
                position: absolute;
                bottom: 100px;
                right: 0px;
                width: 100%;
            }
          }
        }
      }
    }
    .main-content {
      .view-portada-destacados {
        .view-header {
          .destacados-portada {
          }
        }
        .fila {
          .views-field-title {
            a {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .block-views-blocklistado-noticias-block-1{
     .view-content{
        .bloque-noticia-horizontal{
           a{
            .views-field-field-fecha-{
              width: 100%;
            }
          }
        }
      }
  }
}
