/**
 * @file
 * Styles for Bootstrap Barrio's breadcrumbs.
 */

.breadcrumb {
    font-size: 0.929em$font-size-sm;
}

.region-breadcrumb {
    padding: 0 15px 0.25em;
    width: 100%;
}