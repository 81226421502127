/* IMPORTS */

//@import url(font-awesome.min.css);
// variables
@import "variables";
// typography
@import "typography";
//bootstrap
@import "../node_modules/bootstrap/scss/bootstrap";
//material design bootstrap
//@import "../node_modules/mdbootstrap/scss/mdb-free.scss";
// mixins
@import "mixins";
// barrio
@import "barrio";
//header
@import "header";
//footer
@import "footer";
//paxinas
@import "paxinas";
//front
@import "front";
//nodos
@import "nodos";
// CAMBIOS IMPORTADOS DE PRODUCCIÓN OU DE OUTRAS ORIXES PERO PARA FACER COPY-PASTE, NUN PROCESO RÁPIDO DE INTEGRACIÓN
@import "cambios";
