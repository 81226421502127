
/* Paxina de administracion*/
.administra-gestor{
  h2 {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .bot {
    background: #e6e6e6;
    padding: 20px;
    color: #101010;
    margin-bottom: 40px;
    text-align: center;
    min-height: 180px;
    border-radius: 10px;
    a{
      margin-bottom:5px;
    }
  }
  .botblock{
    height: 100px;
    background: #e6e6e6;
    padding: 20px;
    color: #101010;
    margin-bottom: 40px;
    text-align: center;
    border-radius: 10px;
  }
  .add{
    margin-right: 20px;
  }
  .admin-section-title {
    color: #ed3000;
    font-size: 28px;
    border-bottom: 1px solid #ed3000;
    width: 380px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
}

.view-administra-contido{
  margin-bottom: 50px;
  .view-header{
    margin-bottom: 50px;
  }
  .form-item{
    margin-right: 20px;
  }
  .select-wrapper,
  input {
      display: grid!important;
  }
  .form-actions{
    margin-top: 20px;
    button{
      color: #ffffff !important;
      padding: 7px 50px;
      text-decoration: none;
      background-color: #d8ae48;
    }
  }
  td{
    a{
      text-decoration:none;
    }
  }
}

.path-page-admin{
  .administra-gestor{
    .row{
      .fila:last-child{
        display: none;
      }
    }
  }
}

/* SITEMAP */
.path-sitemap{
  .nav{
    a{
      color:$azul_xunta;
      &:hover{
        opacity: 0.6;
      }
    }
  }
}

/* Páxina de búsqueda */
.path-search{
  .search-form{
    display: none;
  }
  .main-content{
    padding:0;
  }
  .pagination{
    justify-content: flex-end !important;
  }
  h2{
    font-size: 1.5rem;
    margin-bottom: 30px;
    padding-left: 15px;
  }
  h3{
    font-size: 1rem;
  }
  ol.node_search-results{
    flex-wrap: wrap;
    flex-direction: row;
    .list-group-item{
      flex-basis: 33.33%;
      border:none !important;
      margin-bottom: 30px;
      a{
        color: #010917;
        text-decoration: none;
        font-weight: bold;
        &:hover{
          color: #4C6B80;
        }
      }
    }
  }
}

/* Paxina de de erros 403, 404 e paxinas sin contido*/
.container-sin-resultados {
  text-align: center;
  .imaxe-sin-resultados {
    background-image: url(../images/icons/sin-resultados.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    margin: 80px auto 20px;
    display: block;
    background-size: contain;
    height: 250px;
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
    color: #757575;
    font-size: 1.3rem;
  }
  p {
    font-weight: 400;
    color: #757575;
    margin-bottom: 50px;
    font-size: .9rem;
  }
}


.post-header{
  background-color: #007bc4;
  color: #fff;
  padding-bottom: 10px;
  margin-bottom: 20px;
  .block-system-breadcrumb-block {
    padding-top: 15px;
    margin-bottom: 32px;
    .breadcrumb{
      background-color: #007bc4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  a{
    color: #fff;
    text-decoration: none;
  }
  a:hover{
    color: #fff;
    text-decoration: underline;
  }
}


/* Información sectorial */
.block-views.block-views-blockinformacion-sectorial-enlaces-lateral-block-1{
    background-color: #F5F9FF;
    padding: 28px;
}


.block-views-blockinformacion-sectorial-enlaces-lateral-block-1 {
  .view-header {
    margin-bottom: 16px;
    p {
      font-size: .9rem;
      font-weight: 900;
      color: #010917;
    }
    .may{
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      text-transform: uppercase;
    }
  }
  .view-content {
    padding: 0px 0px 0px 15px;
    .views-row{
      padding-left: 0px;
    }
    .views-field-name {
      a {
        text-decoration: none;
        color: #4C6B80;
        margin-bottom: 8px;
        display: block;
      }
      a:hover,
      a:active,
      a:focus
       {
        color: #375266;
      }
    }
  }
}

.view-taxonomy-term.view-display-id-page_2 ,
.view-listado-de-informacion-sectorial {
  .view-header p{
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #010917;
      text-decoration: none;
  }
  .view-content{
    .slick-track {
      margin-left: 0px;
      margin-right: 0px;
      .slick-slide {
        margin: 0px 20px 0px 0px;
      }
    }
    h3{
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      color: #010917;
      text-decoration: none;
      margin-bottom: 16px;
    }
    .views-view-grid{
      margin-bottom: 24px;
      border-bottom: 1px solid #E6EBF2;
      padding-bottom: 16px;
      .views-field-field-imagen-del-acceso-de-infor{
        img{
          width: 100%;
          object-fit: cover;
          height: 120px;
        }
      }
      a{
        font-size: 14px;
        color: #010917;
        text-decoration: none;
        line-height: 20px;
        margin-top: 8px;
        display: inline-block;
      }
    }
  }
}

#block-linkdeacceso{
  .field--name-body{
    width: 100% !important;
    padding: 28px;
    max-width: 100%;
    a{
      text-decoration: none;
    }
  }
}

// Sidebar Menú

aside {
  nav.menu--tematicas,
  nav.menu--menu-informacion-sectorial {
    background-color: #F5F9FF;
    padding: 28px!important;
    margin: 0px;
    h2 {
      margin: 0px 0px 16px 0px;
      border-bottom: 0px;
      padding-bottom: 0px;
      text-shadow: none;
      font-size: .9rem;
      font-weight: bold;
      color: #010917;
      text-transform: uppercase;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0px;
      margin: 0px !important;
      li {
        display: block;
        width: 100%;
        a {
          text-decoration: none;
          color: #4C6B80;
          margin-bottom: 8px;
          display: block;
          padding: 0px;
        }
        a:hover{
          color: #010917;
        }
      }
    }
  }
}
// Boton azul
.boton-azul {
  background: #007BC4;
  border-radius: 4px;
  text-decoration: none !important;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF!important;
  padding: 8px 18px;
  &:hover {
    text-decoration: none !important;
    color: #0061B2!important;
    background: #fff;
    border:1px solid #0061B2;
  }
  &:focus {
    text-decoration: none !important;
    color: #0061B2!important;
    background: #fff;
    border:1px solid #0061B2;
  }
}


// Coordinación
.block-pestanas-page-block.d-none,
.block-pestanas-page-block {
  background-color: #F5F9FF;
  padding: 0px 0px 0px 0px;
  margin: 30px 0px;
  display: block!important;
  .nav-tabs {
    background-color: #fff;
    border: 0px;
    padding: 0px;
    .nav-item {
      list-style: none;
      margin-bottom: 0px !important;
      position: relative;
      max-width: 33.33%;
      &:before {
        content: "";
      }
    }
    .nav-link {
      border: 0px;
      font-weight: 700;
      color: #4C6B80;
      height: 100%;
    }
    .nav-link.active {
      background-color: #F5F9FF;
      color: $azul_xunta;

    }
  }
  .tab-content {
    padding: 20px 15px 20px 15px;
  }
}

// Listas
.node__content {
  .body-container ul,
  .tab-content ul,
  .card-body ul,
  .field__item ul{
    //padding-left: 2rem;
    //display: table-cell;
    li {
      list-style: none;
      margin-bottom: 10px;
      position: relative;
      &:before {
        position: relative;
        top: 0px;
        left: -8px;
        font-family: 'Font Awesome 5 Free';
        content: "\f061";
        font-size: .8rem;
        font-weight: bold;
        color: $azul_xunta;
        text-decoration: none;
      }
      ul {
        margin: 15px 0px 0px 0px;
      }
      a{
        &:hover{
          text-decoration: underline;
        }
      }

  }

  }
  .nav-item{
    position: initial;
  }
}
.field--name-field-enlaces-da-publicacion{
  a{
    &:before {
      position: relative;
      top: 0px;
      left: 0px;
      font-family: 'Font Awesome 5 Free';
      content: "\f061";
      font-size: .8rem;
      font-weight: bold;
      color: $azul_xunta;
      text-decoration: none;
      margin-right: 8px;
    }
  }
}

// Migas de Pan
nav {
  .breadcrumb {
    li {
      color: #fff;
      a {
        color: #fff;
        font-weight: bold;
        &:hover {
        color: #fff;
        font-weight: bold;
        }
      }
    }
    li.active {
      color: #FFFFFF;
      font-weight: bold;
    }
  }
}


// Node Página Básica

.node__content {
    font-size: 1rem;
    margin-top: 5px;
    h3:not(:first-child){
      margin-top: 32px;
    }
    .field--name-field-enlaces-da-publicacion{
      background-color: #F5F9FF;
      padding: 20px;
      margin-top: 20px;
      .field__label{
        margin-top: 0;
      }
    }
}

.path-frontpage {
  #main {
    margin-top: 0px;
  }
}
#main {
  margin-top: 0px;
}
.path-node {
  .node__content {
    margin-bottom: 50px;
    top: 0px;
    .field--type-datetime {
      font-size: 12px;
      font-weight: 700;
      color: #4C6B80;
      padding-bottom: 25px;
      padding-top: 10px;
    }
  }
}
.field__label{
    display: block;
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 5px;
    color: #010917;
    .gris{
      color: #4C6B80;
    }
}

// Nodo novas
.node--type-noticias {
  .field__label .gris{
    color: #4C6B80;
  }

  .field--name-body a {
    color: #007bc4;
    text-decoration: none;
    word-break: break-all;
  }

  .field--name-field-imaxe-da-nova {
    //margin-left: 24px;
    .slick-track{
      float:left;
      img{
        height: auto;
        width: 100%;
      }
    }
  }
  .field.field--name-field-enlaces-da-publicacion {
    //margin-left: 24px;
    .field--name-field-enlaces-da-publicacion .field__item a {
      position: relative;
      padding-left: 22px;
      margin-bottom: 6px;
      display: block;
    }
    .field--name-field-enlaces-da-publicacion .field__item a:before {
      position: absolute;
      top: 0px;
      left: 0px;
      font-family: 'Font Awesome 5 Free';
      content: "\f0c1";
      font-size: 1rem;
      font-weight: bold;
      color: $azul_xunta;
      text-decoration: none;
    }

  }
}
// Node Páginas IDE
.path-node {
  .node__content {
      .slider-page,
      .normativa-page,
      .enlaces-relacionados-page,
      .otros-page {
        h3 {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 15px;
          color: #4C6B80;
          strong {
            font-weight: 700;
            color: #010917;
          }
        }
      }
      .slider-page {
        margin-bottom: 25px;
        margin-top:40px;
        .slick-slide {
          &:hover {
            cursor: pointer;
            a {
              font-size: 25px;
            }
          }
        }
        .col-md-12{
          padding:0;
        }
      }
      .normativa-page {
        margin-bottom: 25px;
        .field__item{
          margin-bottom:8px;
        }
        .col-md-12{
          padding:0;
        }
      }
      .enlaces-relacionados-page {
        margin-bottom: 25px;
        background-color: #F5F9FF;
        padding: 20px 20px;
        .field__item{
          margin-bottom:8px;
        }
        a {
          position: relative;
          padding-left: 30px;
          &:before {
            position: absolute;
            top: -6px;
            left: 2px;
            font-family: 'Font Awesome 5 Free';
            content: "\f0c1";
            font-size: 1rem;
            font-weight: bold;
            color: $azul_xunta;
            text-decoration: none;
          }
        }
      }
      .otros-page {
        margin-bottom: 25px;
      }
    .row-bloque-contacto {
      margin: 10px -15px 0px -15px;
      .container-bloque-contacto {
        padding: 20px;
        background-color: $azul_xunta;
        display: flex;
        flex-wrap: wrap;
        p {
          color: #fff;
          font-weight: 400;
        }
        .row {
          margin-top: 15px;
          align-self: flex-end;
          width:100%;
          & > div {
            display: flex;
            align-items: center;
            width: auto;
            margin-bottom: 15px;
            i {
              display: inline-block;
              color: #fff;
              font-size: .9rem;
              margin-right: 8px;
            }
            div,a {
              color: #fff;
              font-weight: 400;
              line-height: 14px;
              font-size: .75rem;
              text-decoration: none;
              &:hover {
                text-decoration: none;
              }
            }
            a{
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

// /ide/inspire
.page-node-26 {
  .col {
    width: 73.666667%;
    flex: 0 0 auto;
  }
}


.page-node-57,
.page-node-52{
  .col {
    width: 66.66666667%;
    flex: 0 0 auto;
  }
}

// Enlaces PDF
.file--application-pdf {
    background-image: none;
    position: relative;
    padding-left: 30px;
    &:before {
      position: absolute;
      top: 0px;
      left: 0px;
      font-family: 'Font Awesome 5 Free';
      content: "\f0c1";
      font-size: 1rem;
      font-weight: bold;
      color: $azul_xunta;
      text-decoration: none;
    }
}

// Formulario Colabora
.webform-submission-formulario-colabora-form {
  margin: 0px 0px 40px 0px;
  .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px;
    h3 {
      font-weight: 400;
      font-size: 18px;
      color: #4C6B80;
      strong {
        color: #010917;
      }
    }
    label {
      font-weight: 700;
      font-size: .8rem;
      color: #4C6B80 !important;
      margin-bottom: 2px !important;
    }
    input, textarea{
        &::placeholder{
        color:$placeholder;
      }
    }
    .js-form-type-webform-terms-of-service{
      a{
        color:$azul_xunta;
      }
    }
    .form-control{
      border: 1px solid #C5CFD6 !important;
    }
    #edit-container-button {
      display: flex;
      flex-wrap: wrap;
      .form-type-webform-terms-of-service {
        display: inline-block;
        width: 70%;
        .form-check-label {
          font-weight: 400;
        }
      }
      .captcha{
        width: 70%;
      }
      .webform-actions {
        display: inline-block;
        width: 30%;
        text-align: right;
        button {
          padding: 8px 22px;
          background-color: $azul_xunta;
          border-color: $azul_xunta;
          &:hover{
            background: #fff;
            color: $azul_xunta;
            border: 1px solid $azul_xunta;
          }
        }
      }
    }
  }
  .btn-primary {
    color: #fff;
    background-color: $azul_xunta;
    border-color: $azul_xunta;
    &:hover{
      background-color: #FFF;
      color:$azul_xunta;
    }
  }
}


// Página NOTICIAS
.page-node-48 {
 .post-header {
   .container-fluid {
     display: flex;
     flex-wrap: wrap;
     #block-arqbootstrap4-breadcrumbs {
       width: 100%;
       order: 0;
     }
     #block-views-block-slider-ultimas-publicaciones-block-1 {
       width: 100%;
       order: 2;
     }
     .page-title {
       width: 100%;
       order: 1;
     }
   }
 }
 .container-sin-resultados{
  .titulo{
   font-style: normal;
   font-weight: bold;
   font-size: 28px;
   line-height: 34px;
   text-align: left;
  }
}
}

.view-slider-ultimas-publicaciones {
      .slick-slider .slick-prev,
      .slick-slider .slick-next {
          top: -20px!important;
      }
      .slick-slider .slick-prev,
      .slider-page .slick-prev {
          left: -20px;
      }
      .slick-slider .slick-prev:before,
      .slider-page .slick-prev:before {
          color: #fff;
      }

      .slick-slider .slick-next,
      .slider-page .slick-next {
        right: 10px;
      }
      .slick-slider .slick-next:before,
      .slider-page .slick-next:before {
        color: #fff;
      }


  .slick-track {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    .slick-slide {
      display: inline-block;
      float: none;
      border-bottom: 2px solid #fff;
      /*margin-right: 25px;*/ /*Para que mostre parte do ultimo elemento*/
      margin: 0px 15px ;
      a {
        text-decoration: none;
        transition: .3s ease;
        &:hover {
          text-decoration: none;
          opacity: 0.8;
        }
      }
      .views-field-title {
        font-size: 1.25rem;
        font-weight: 700;
        text-decoration: none;
        margin-bottom: 5px;
      }
      .views-field-field-fecha- {
        font-size: 12px;
        color: #FFF;
        margin-bottom: 16px;
      }
      .views-field-body {
        font-weight: 400;
      }
    }
  }
}

table {
  margin:30px 0px 10px 0px;
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  td, th {
    border: 1px solid #e6ebf2;
    padding: 3px 2px;
  }
  tbody td {
    font-size: 16px;
    padding: 7px 10px;
  }
  tbody tr{
    background-color:#F5F9FF;
  }
  tr:nth-child(even) {
    background: #ffffff;
  }

  thead {
    background: $azul_xunta;
    border: 1px solid $azul_xunta;
  }
  thead th {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    border-left: 1px solid #e6ebf2;
    padding: 7px 10px;
  }
  thead th:first-child {
    border-left: none;
  }

}

.pestanas-page .tab-content img {
    //float: left;
    margin: 20px;
    margin-left: 30%;
    width: 40%;
    height: auto;
}

.block-views-blocklistado-noticias-block-1{
  .view-listado-noticias {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px;
    nav {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 30px;
    }
  }
  .view-header {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 16px;
    h2 {
      font-weight: 700;
      margin-bottom: 20px;
      font-size: 28px;
    }
  }
  .view-filters {
    width: 25%;
    .views-exposed-form {
      background-color: #F5F9FF;
      padding: 40px;
      .view-header {
        width: 100%;
        padding: 0px;
        p {
          font-size: .9rem;
          font-weight: 900;
          color: #010917;
        }
      }
      .form-row {
        justify-content: flex-end;
        .form-item-field-fecha__value:after,
        .form-item-field-fecha__value-1:after {
            content: url(../images/icons/calendar_icon.png);
            left: 18px;
            bottom: 2px;
            text-decoration: none;
            display: inline-block;
            position: absolute;
        }
        fieldset {
          width: 100%;
          position: relative;
          margin-bottom: 8px!important;
          label {
            font-weight: 700;
            font-size: .7rem;
            color: #4C6B80;
            margin-bottom: .2rem;
          }
          .form-control{
            padding: .375rem .75rem .375rem 2.4rem;
          }

          .form-control {
            padding: .375rem .75rem .375rem 2.4rem;
          }
        }
        .js-form-item-title .form-control{
            padding: .375rem .75rem .375rem 14px;
        }
        .form-actions {
          margin: 20px 0px 0px 0px;
          display: flex;
          flex-direction: row-reverse;
          .btn-primary {
            padding:  8px 16px;
            font-size: 1rem;
            font-weight: 700;
            background: #007BC4;
            border-radius: 5px;
            border:1px solid #007BC4;
            &:hover,
            :focus {
              background: #fff;
              color: #007BC4;
              border:1px solid #007BC4;
            }
          }
          .btn-primary[data-drupal-selector="edit-reset"] {
            padding:  8px 16px;
            font-size: 1rem;
            font-weight: 700;
            background: $dark_blue;
            border-radius: 5px;
            border:1px solid $dark_blue;
            margin-right: 10px;
            &:hover,
            :focus {
              background: #fff;
              color: #007BC4;
              border:1px solid #007BC4;
            }
          }
        }
      }
    }
  }
  .view-content {
    width: 73%;
    padding: 0px 0px 0px 2%;
    .bloque-noticia-horizontal {
      margin-bottom: 20px;
      a {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        text-decoration: none;
        .views-field-field-fecha- {
          display: inline-block;
          min-width: 165px;
          font-size: 12px;
          color: #A6B5C0;
        }
        .views-field-container {
          display: inline-block;
          flex:1;
          border-bottom: 1px solid #E6EBF2;
          .views-field-title {
            display: block;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 5px;
            color:#010917;
          }
          .views-field-body {
            display: block;
            color:#4C6B80;
            font-weight:400;
            margin-bottom: 20px;
          }
        }
      }
      a:hover {
          opacity: 0.8;
      }
    }
  }
  .view-empty{
    width: 73%;
    padding: 0px 0px 0px 2%;
  }
}

// FORMULARIO DE CONTACTO
.page-node-626{
  .post-header{
    margin-bottom: 0;
  }
  .ui-dialog{
    position: absolute !important;
    width: 80% !important;
    top:100px !important;
    left: 10% !important;
  }
}
.webform-submission-formulario-de-contacto-form{
  margin-top: -35px;
  .webform-flexbox {
    flex-wrap: wrap;
    > .webform-flex:first-child{
      flex-basis: 100%;
      @media (min-width: 992px){
        flex-basis: 60%;
      }
      @media (min-width: 1200px){
        flex-basis: 70%;
      }
      .formulario{
        flex-wrap: wrap;
        .webform-flex:nth-child(1),.webform-flex:nth-child(2){
          flex-basis: 50%;
        }
        .webform-flex:nth-child(3),.webform-flex:nth-child(4),.webform-flex:nth-child(5){
          flex-basis: 100%;
        }
        label{
          color:$dark_blue;
          font-size: 10px;
          line-height: 12px;
          font-weight: bold;
        }
        input,textarea{
          -webkit-text-fill-color:#A7ADB6;
          font-size: 16px;
          border-radius:3px;
          border: 1px solid #C5CFD6;
          &:hover, &:focus{
            border: 2px solid $azul_xunta;
            box-shadow: none;
          }
        }
        textarea{
          border-radius: 3px;
        }
        .form-type-webform-terms-of-service{
          font-size: 12px;
          input{
            font-size: 12px;
          }
          a{
            text-decoration: none;
            color:#007BC4;
          }
        }
      }
    }
    > .webform-flex:last-child{
      flex-basis: 100%;
      @media (min-width: 992px){
        flex-basis: 40%;
      }
      @media (min-width: 1200px){
        flex-basis: 30%;
      }
      .informacion{
        background-color: #fafaf9;
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        >div{
          flex-basis: 100%;
        }
        .titulo{
          font-size:18px;
          font-weight: bold;
          margin-bottom: 13px;
          .sub{
            color:$dark_blue;
            font-weight: 400;
          }
        }
        .tel1, .mail{
          flex-basis: 100%;
          margin-top: 24px;
          @media (min-width: 400px){
            flex-basis: 50%;
          }
          i{
            margin-right: 10px;
          }
        }
        .mail{
          a{
            color:#212529;
            text-decoration: none;
          }
        }
        .redes{
          display: flex;
          margin-top: 24px;
          >div{
            margin-right:20px;
            i{
              color:#212529;
            }
          }
        }
      }
    }
  }
  #edit-actions{
    text-align: right;
    width: 100%;
    padding-right: 15px;
    @media (min-width: 992px){
      width: 60%;
    }
    @media (min-width: 1200px){
      width: 70%;
    }
    button{
      background-color: $azul_xunta;
      border-color: $azul_xunta;
      border-radius:5px;
      padding: 10px 20px;
      line-height: 16px;
      &:hover{
        color: #fff;
        background-color: #0b5ed7;
        border-color: #0a58ca;
      }
    }
  }
}

// SERVIZOS
.page-node-674{
  .sidebar_first{
    width: 100% !important;
    @media (min-width: 768px){
      width: 40% !important;
    }
    @media (min-width: 992px){
      width: 30% !important;
    }
    @media (min-width: 1400px){
      width: 25% !important;
    }
  }
  #block-arqbootstrap4-content{
    display: none;
  }
}
.view-servizos{
  .view-header{
    margin-bottom: 36px;
    .titulo{
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
  }
  .views-exposed-form{
    display: none;
  }
  .views-view-grid{
    .row{
      .col-12{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E6EBF2;
      }
      .views-field-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .views-field-field-corpo{
        color:#4C6B80;
      }
      .views-field-edit-node{
        margin-bottom: 20px;
      }
      .etiquetas{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        > div{
          font-size: 12px;
          line-height: 12px;
          padding: 6px 8px;
          height: 22px;
          margin-right: 20px;
        }
        .tipo{
          background-color: $azul_xunta;
          color:#FFF;
        }
        .formato{
          background-color: $dark_blue;
          color:#FFF;
        }
        .organismo{
          color: $dark_blue;
          border-left: 2px solid $dark_blue;
          font-weight: bold;
          margin-right: 0;
        }
      }
      .enlaces{
        display: flex;
        flex-wrap: wrap;
        >div{
          margin-right: 50px;
        }
        a{
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: 0px;
          text-decoration: none;
          color:$azul_xunta;
          padding-left: 30px;
          display: block;
          height: 20px;
        }
        .enlace{
          a{
            background-image: url("../images/icons/cadena.svg");
          }
        }
        .capacidad{
          a{
            background-image: url("../images/icons/laptop.svg");
          }
        }
        .relacionada{
          a{
            background-image: url("../images/icons/info.svg");
          }
        }
      }
      .views-field-nothing-2, .views-field-field-tipoloxia{
        margin-top:35px;
        font-size: 12px;
        line-height: 12px;
        color: $dark_blue;
      }
      .views-field-field-tipoloxia{
        margin-top: 10px;
      }
    }
  }
  .pagination{
    justify-content: flex-end;
    /*
    .page-link{
      border-radius: 5px !important;
    }
    a, .active .page-link{
      font-size: 14px;
      line-height: 22px;
    }
    a{
      color:#505B6D !important;
    }
    */
  }
}

.block-views-exposed-filter-blockservizos-page-1, .block-views-exposed-filter-blockiniciativas-page-1, .block-views-exposed-filter-blocknodos-xunta-page-1{
  background-color: #F5F9FF;
  padding: 40px;
  h2{
    font-size: .9rem;
    font-weight: 900;
    color: #010917;
    margin-bottom: 16px;
  }
  fieldset{
    width: 100% !important;
    select{
      color:#4C6B80;
    }
  }
  div[data-drupal-selector="edit-actions"]{
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    button{
      margin-left:10px;
      &:nth-child(2){
        background: $dark_blue;
        border:1px solid $dark_blue;
      }
    }
    .btn-primary {
      padding:  8px 16px;
      font-size: 1rem;
      font-weight: 700;
      background: $azul_xunta;
      border-radius: 5px;
      border:1px solid $azul_xunta;
      &:hover,
      :focus {
        background: #fff;
        color: $azul_xunta;
        border:1px solid $azul_xunta;
      }
    }
  }
  label{
    font-weight: 700;
    font-size: .7rem;
    color: #4C6B80;
    margin-bottom: .2rem;
  }
}

.block-views-exposed-filter-blockiniciativas-page-1, .block-views-exposed-filter-blocknodos-xunta-page-1{
  .form-item-field-data-iniciativa-value, .form-item-field-data-iniciativa-value-1{
    position: relative;
    &::after{
      content: url(../images/icons/calendar_icon.png);
      left: 18px;
      bottom: 2px;
      text-decoration: none;
      display: inline-block;
      position: absolute;
    }
    input{
      padding: 0.375rem 0.75rem 0.375rem 2.4rem;
    }
  }
}

// INICIATIVAS
// Se oculta el sidebar 2 porque eliminar o bloque de novas desta páxina implica modificar todas as rutas das páxinas indicadas neste bloque.
.page-node-51{
  #sidebar_second{
    display: none;
  }
}
.view-iniciativas, .view-nodos-xunta{
  .view-header{
    margin-bottom: 50px;
    .titulo{
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 34px;
    }
  }
  .views-exposed-form{
    display: none;
  }
  .views-view-grid{
    .row{
      .col-12{
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E6EBF2;
      }
      .views-field-title{
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }
      .views-field-body{
        color:#4C6B80;
      }
      .views-field-edit-node{
        margin-bottom: 20px;
      }
      .views-field-field-ligazon-principal{
        text-align: right;
        margin-bottom: 10px;
        a{
          background-image: url("../images/icons/arrow-right.svg");
            background-size: 8px;
            background-repeat: no-repeat;
            background-position: 85% 43%;
            background-color: $azul_xunta;
            padding: 8px 40px 8px 20px;
            border-radius:5px;
            font-size: 16px;
            font-weight: bold;
            text-decoration: none;
            color: #FFF;
            line-height: 14px;
            &:hover{
              background-image: url("../images/icons/arrow-right-xunta.svg");
              background-color: #fff;
              color: $azul_xunta;
              border: 1px solid $azul_xunta;
            }
        }
      }
      .views-field-field-ligazons-iniciativas{
        margin-top: 5px;
        .list-group{
          margin:0;
          .list-group-item{
            border:none;
            a{
              background-image: url("../images/icons/cadena.svg");
              background-size: 20px;
              background-repeat: no-repeat;
              background-position: 0px;
              text-decoration: none;
              color:$azul_xunta;
              padding-left: 30px;
              display: block;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .pagination{
    justify-content: flex-end;
    .page-link{
      border-radius: 5px !important;
    }
    a, .active .page-link{
      font-size: 14px;
      line-height: 22px;
    }
    a{
      color:#505B6D !important;
    }
  }
}

// COORDINACION/SERVICIOS QUITAMOS SANGRADO EN UL
.page-node-674{
  ul{
    padding-left:0;
  }
}

// MODAL TERMOS DOS SERVICIOS CONTACTO
.ui-dialog{
  .ui-dialog-titlebar{
    background-color: white;
    border:none;
    .ui-dialog-titlebar-close{
      background-color: white;
      border:none;
    }
  }
  .ui-dialog-content{
    padding: 0.5em 3em 3em 3em;
  }
  .webform-terms-of-service-details--content{
    font-family: "Xunta Sans";
    color: $main;
  }
}

// PUBLICACIÓNS
.view-publicaciones{
  .views-view-grid{
    .row{
      >.col-12{
        margin-bottom:40px;
      }
    }
  }
  .views-field-nothing{
    .field-content{
      display: flex;
      flex-wrap: wrap;
      @media (min-width: 400px) {
        flex-wrap: nowrap;
      }
      .imaxe{
        flex-basis: 100%;
        @media (min-width: 400px) {
          flex-basis: 40%;
        }
        @media (min-width: 576px) {
          flex-basis: auto;
        }
        img{
          width: 100%;
          @media (min-width: 576px) {
            width: 200px;
          }
          height: auto;
        }
      }
      .contido{
        flex-basis: 100%;
        margin-top: 40px;
        @media (min-width: 400px) {
          flex-basis: 60%;
          margin-top: 0px;
          margin-left:20px;
        }
        @media (min-width: 576px) {
          flex-basis: auto;
          margin-top: 0px;
          margin-left:20px;
        }
        padding-bottom: 20px;
        border-bottom: 1px solid #E6EBF2;
        .titulo{
          color:$main;
          font-size: 20px;
          line-height: 20px;
          font-weight: 700;
          margin-bottom:15px;
        }
        .logo-descarga,.texto-descarga{
          display: none;
        }
        a{
          &.descarga{
            margin-top:25px;
            display: flex;
            font-size: 14px;
            line-height: 24px;
            text-decoration: none;
            color:$azul_xunta;
            .logo-descarga{
              display: block;
              background-image: url(../images/icons/descarga.svg);
              background-position: 0px 3px;
              background-repeat: no-repeat;
              width: 24px;
            }
            .texto-descarga{
              display: block;
            }
          }
        }
      }
    }
  }
}

/*Pestanas en pax basica
.block-pestanas-page-block {
  padding-top: 30px;
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #002b4a!important;
    background-color: transparent!important;
    border-right: 2px solid #002b4a;
    border-radius: 0px;
  }
}



@media (max-width: 575px) {
  .block-pestanas-page-block {
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      border-bottom: 2px solid #002b4a;
      border-right: none!important;
      margin-bottom:10px;
    }
    .nav-pills .nav-link.active, .nav-pills .>.nav-link {
      border-bottom: 2px solid #002b4a;
      border-right: none!important;
      margin-bottom:10px;
    }
  }
}
*/
///ide/ideg
.view-id-slider_de_enlaces_por_nodo{
  .slick-slider .slick-slide .views-row, .slider-page .slick-slide .views-row{
    width: 100% !important;
  }
  .slick-list{
    padding-left: 0;
  }
  .view-content{
    margin:0;
  }
}
.page-node-52{
  .row-bloque-contacto{
    display: flex;
  }
}


/* xs */
@media (max-width: 767.98px) {
  .page-node-52{
    .row-bloque-contacto{
      display: flex;
      flex-wrap: wrap;
      .col-md-6:last-child{
        margin-top:10px;
      }
    }
  }
  #main {
    margin-top: 30px;
  }
  nav .breadcrumb li {
    margin-bottom: 5px;
    line-height: 16px;
  }
  .block-views-blocklistado-noticias-block-1  {
    .view-filters {
      width: 100%;
      padding: 0px 15px;
    }
    .view-content, .view-empty {
      width: 100%;
      padding: 0px 15px;
      margin-top: 50px;
    }
    .view-listado-noticias nav {
      justify-content: center;
      width: auto;
      margin: 30px 15px 0px 15px;
      ul {
        flex-wrap: wrap;
        justify-content: center;
        li {
        }
      }
    }
  }
  .page-node-52 .col,
  .page-node-57 .col,
  .page-node-26 .col {
    width: 100%;
  }
}

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  #main {
    margin-top: 30px;
  }
  nav .breadcrumb li {
    margin-bottom: 5px;
    line-height: 16px;
  }
  .block-views-blocklistado-noticias-block-1  {
    .view-filters {
      width: 40%;
      padding: 0px 15px;
    }
    .view-content {
      width: 60%;
      padding: 0px 15px;
    }
  }
  .page-node-50,
  .page-node-51{
    .sidebar_first{
      width: 40%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-node-50,
  .page-node-51{
    .sidebar_first{
      width: 40%;
    }
  }
 }

.node--type-page .link-interno{
    clear: both;
    display: inline-block;
    margin: 20px 40px;
    text-decoration: none;
    border: 1px solid $azul_xunta;
    padding: 4px 10px 1px 10px;
    width: 24%;
    text-align: center;
}


.footer .col-xs-12.col-md-2:last-child{
  background-image: url('../images/xacobeo21-22-negativo.png');
  background-repeat: no-repeat;
    background-position: 0 0px!important;
    float: right;
    background-size: contain;
    height: 30px;
    width: 190px;
    background-size: 160px;
    background-repeat: no-repeat;
}
