@charset "UTF-8";
/*
Theme Name: Bootstrap 4 Sass
Description: Bootstrap 4 with Sass
*/
/* IMPORTS */
/* VARIABLES */
/* TYPOGRAPHY */
/* Google Fonts */
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #0079C0;
  --secondary: #ff4e2e;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #343a40;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: bold; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0079C0;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #005283;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.375rem + 1.5vw) ; } }

h2, .h2 {
  font-size: 2rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.325rem + 0.9vw) ; } }

h3, .h3 {
  font-size: 1.75rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.3rem + 0.6vw) ; } }

h4, .h4 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h4, .h4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.725rem + 5.7vw) ; } }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.675rem + 5.1vw) ; } }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.575rem + 3.9vw) ; } }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.475rem + 2.7vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: normal; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: bold; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #343a40; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #343a40;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d9ed; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7ab9de; }

.table-hover .table-primary:hover {
  background-color: #a4cee8; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a4cee8; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #ffcdc4; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #ffa392; }

.table-hover .table-secondary:hover {
  background-color: #ffb7ab; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #ffb7ab; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #41b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #343a40;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: normal;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #343a40;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }
  .btn-primary:hover {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d;
    box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #00598d;
    border-color: #005180; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #ff4e2e;
  border-color: #ff4e2e; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #ff2e08;
    border-color: #fa2600; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #ff2e08;
    border-color: #fa2600;
    box-shadow: 0 0 0 0.2rem rgba(255, 105, 77, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #fa2600;
    border-color: #ed2400; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 105, 77, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #0079C0;
  border-color: #0079C0; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0079C0;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.btn-outline-secondary {
  color: #ff4e2e;
  border-color: #ff4e2e; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 78, 46, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #ff4e2e;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 78, 46, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: normal;
  color: #0079C0;
  text-decoration: none; }
  .btn-link:hover {
    color: #005283;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #343a40;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: normal;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0079C0; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0079C0;
    background-color: #0079C0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #41b9ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #74cbff;
    border-color: #74cbff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0079C0;
  background-color: #0079C0; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 121, 192, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 121, 192, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #41b9ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #41b9ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #74cbff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #74cbff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0079C0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #74cbff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0079C0; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0079C0;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #005283;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #0079C0; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #00598d; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #ff4e2e; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #fa2600; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 78, 46, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: bold; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #003f64;
  background-color: #cce4f2;
  border-color: #b8d9ed; }
  .alert-primary hr {
    border-top-color: #a4cee8; }
  .alert-primary .alert-link {
    color: #001f31; }

.alert-secondary {
  color: #852918;
  background-color: #ffdcd5;
  border-color: #ffcdc4; }
  .alert-secondary hr {
    border-top-color: #ffb7ab; }
  .alert-secondary .alert-link {
    color: #5a1c10; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0079C0;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #343a40;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #003f64;
  background-color: #b8d9ed; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #003f64;
    background-color: #a4cee8; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #003f64;
    border-color: #003f64; }

.list-group-item-secondary {
  color: #852918;
  background-color: #ffcdc4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #852918;
    background-color: #ffb7ab; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #852918;
    border-color: #852918; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.275rem + 0.3vw) ; } }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #343a40; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #0079C0 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #00598d !important; }

.bg-secondary {
  background-color: #ff4e2e !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #fa2600 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0079C0 !important; }

.border-secondary {
  border-color: #ff4e2e !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: normal !important; }

.font-weight-bold {
  font-weight: bold !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #0079C0 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #004974 !important; }

.text-secondary {
  color: #ff4e2e !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #e12200 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #343a40 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/* MIXINS */
.btn-primary a {
  color: #fff;
  background-color: #0079C0;
  border-color: #0079C0; }
  .btn-primary a:hover {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d; }
  .btn-primary a:focus, .btn-primary a.focus {
    color: #fff;
    background-color: #00619a;
    border-color: #00598d;
    box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }
  .btn-primary a.disabled, .btn-primary a:disabled {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-primary a:not(:disabled):not(.disabled):active, .btn-primary a:not(:disabled):not(.disabled).active,
  .show > .btn-primary a.dropdown-toggle {
    color: #fff;
    background-color: #00598d;
    border-color: #005180; }
    .btn-primary a:not(:disabled):not(.disabled):active:focus, .btn-primary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 141, 201, 0.5); }

.btn-secondary a {
  color: #fff;
  background-color: #ff4e2e;
  border-color: #ff4e2e; }
  .btn-secondary a:hover {
    color: #fff;
    background-color: #ff2e08;
    border-color: #fa2600; }
  .btn-secondary a:focus, .btn-secondary a.focus {
    color: #fff;
    background-color: #ff2e08;
    border-color: #fa2600;
    box-shadow: 0 0 0 0.2rem rgba(255, 105, 77, 0.5); }
  .btn-secondary a.disabled, .btn-secondary a:disabled {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
  .btn-secondary a:not(:disabled):not(.disabled):active, .btn-secondary a:not(:disabled):not(.disabled).active,
  .show > .btn-secondary a.dropdown-toggle {
    color: #fff;
    background-color: #fa2600;
    border-color: #ed2400; }
    .btn-secondary a:not(:disabled):not(.disabled):active:focus, .btn-secondary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 105, 77, 0.5); }

.btn-success a {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success a:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success a:focus, .btn-success a.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success a.disabled, .btn-success a:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success a:not(:disabled):not(.disabled):active, .btn-success a:not(:disabled):not(.disabled).active,
  .show > .btn-success a.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success a:not(:disabled):not(.disabled):active:focus, .btn-success a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info a {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info a:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info a:focus, .btn-info a.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info a.disabled, .btn-info a:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info a:not(:disabled):not(.disabled):active, .btn-info a:not(:disabled):not(.disabled).active,
  .show > .btn-info a.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info a:not(:disabled):not(.disabled):active:focus, .btn-info a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning a {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning a:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning a:focus, .btn-warning a.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning a.disabled, .btn-warning a:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning a:not(:disabled):not(.disabled):active, .btn-warning a:not(:disabled):not(.disabled).active,
  .show > .btn-warning a.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning a:not(:disabled):not(.disabled):active:focus, .btn-warning a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger a {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger a:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger a:focus, .btn-danger a.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger a.disabled, .btn-danger a:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger a:not(:disabled):not(.disabled):active, .btn-danger a:not(:disabled):not(.disabled).active,
  .show > .btn-danger a.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger a:not(:disabled):not(.disabled):active:focus, .btn-danger a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light a {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light a:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light a:focus, .btn-light a.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light a.disabled, .btn-light a:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light a:not(:disabled):not(.disabled):active, .btn-light a:not(:disabled):not(.disabled).active,
  .show > .btn-light a.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light a:not(:disabled):not(.disabled):active:focus, .btn-light a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark a {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark a:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark a:focus, .btn-dark a.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark a.disabled, .btn-dark a:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark a:not(:disabled):not(.disabled):active, .btn-dark a:not(:disabled):not(.disabled).active,
  .show > .btn-dark a.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark a:not(:disabled):not(.disabled):active:focus, .btn-dark a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary a {
  color: #0079C0;
  border-color: #0079C0; }
  .btn-outline-primary a:hover {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
  .btn-outline-primary a:focus, .btn-outline-primary a.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }
  .btn-outline-primary a.disabled, .btn-outline-primary a:disabled {
    color: #0079C0;
    background-color: transparent; }
  .btn-outline-primary a:not(:disabled):not(.disabled):active, .btn-outline-primary a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary a.dropdown-toggle {
    color: #fff;
    background-color: #0079C0;
    border-color: #0079C0; }
    .btn-outline-primary a:not(:disabled):not(.disabled):active:focus, .btn-outline-primary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 121, 192, 0.5); }

.btn-outline-secondary a {
  color: #ff4e2e;
  border-color: #ff4e2e; }
  .btn-outline-secondary a:hover {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
  .btn-outline-secondary a:focus, .btn-outline-secondary a.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 78, 46, 0.5); }
  .btn-outline-secondary a.disabled, .btn-outline-secondary a:disabled {
    color: #ff4e2e;
    background-color: transparent; }
  .btn-outline-secondary a:not(:disabled):not(.disabled):active, .btn-outline-secondary a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary a.dropdown-toggle {
    color: #fff;
    background-color: #ff4e2e;
    border-color: #ff4e2e; }
    .btn-outline-secondary a:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 78, 46, 0.5); }

.btn-outline-success a {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success a:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success a:focus, .btn-outline-success a.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success a.disabled, .btn-outline-success a:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success a:not(:disabled):not(.disabled):active, .btn-outline-success a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success a.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success a:not(:disabled):not(.disabled):active:focus, .btn-outline-success a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info a {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info a:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info a:focus, .btn-outline-info a.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info a.disabled, .btn-outline-info a:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info a:not(:disabled):not(.disabled):active, .btn-outline-info a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info a.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info a:not(:disabled):not(.disabled):active:focus, .btn-outline-info a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning a {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning a:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning a:focus, .btn-outline-warning a.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning a.disabled, .btn-outline-warning a:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning a:not(:disabled):not(.disabled):active, .btn-outline-warning a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning a.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning a:not(:disabled):not(.disabled):active:focus, .btn-outline-warning a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger a {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger a:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger a:focus, .btn-outline-danger a.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger a.disabled, .btn-outline-danger a:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger a:not(:disabled):not(.disabled):active, .btn-outline-danger a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger a.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger a:not(:disabled):not(.disabled):active:focus, .btn-outline-danger a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light a {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light a:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light a:focus, .btn-outline-light a.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light a.disabled, .btn-outline-light a:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light a:not(:disabled):not(.disabled):active, .btn-outline-light a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light a.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light a:not(:disabled):not(.disabled):active:focus, .btn-outline-light a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark a {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark a:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark a:focus, .btn-outline-dark a.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark a.disabled, .btn-outline-dark a:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark a:not(:disabled):not(.disabled):active, .btn-outline-dark a:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark a.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark a:not(:disabled):not(.disabled):active:focus, .btn-outline-dark a:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark a.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

/**
 * @file
 * Styles for Bootstrap Barrio affix effect.
 */
.affix {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1035;
  left: 0; }

/**
 * @file
 * Visual styles for comments in Barrio.
 */
.alert-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 75vh;
  overflow: scroll;
  z-index: 9999; }

.toast-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999; }

/**
 * @file
 * Bootstrap Barrio specific styling for the Book module.
 */
.book-navigation .menu {
  border-top: 1px solid #dee2e6; }

.book-navigation .book-pager {
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  margin: 0; }

/**
 * @file
 * Styling for the Book module.
 */
.book-navigation .menu {
  padding-bottom: 0;
  padding-top: 1em; }

.book-navigation .book-pager {
  margin: 0;
  overflow: auto;
  padding: 0.5em 0; }

.book-pager__item {
  display: inline-block;
  list-style-type: none;
  vertical-align: top; }

.book-pager__item--previous {
  text-align: left;
  /* LTR */
  width: 45%; }

[dir="rtl"] .book-pager__item--previous {
  float: right;
  text-align: right; }

.book-pager__item--center {
  text-align: center;
  width: 8%; }

.book-pager__item--next {
  float: right;
  /* LTR */
  text-align: right;
  /* LTR */
  width: 45%; }

[dir="rtl"] .book-pager__item--next {
  float: left;
  text-align: left; }

/**
 * @file
 * Styles for Bootstrap Barrio's breadcrumbs.
 */
.breadcrumb {
  font-size: 0.929em 0.875rem; }

.region-breadcrumb {
  padding: 0 15px 0.25em;
  width: 100%; }

/**
 * @file
 * Visual styles for comments in Bartik.
 */
/* This is required to win over specificity of #content h2 */
#content .comment-wrapper h2 {
  margin-bottom: 1em; }

#content .comment-wrapper h2.comment-form__title {
  margin-bottom: 1em; }

.field-node--comment {
  font-size: 0.875rem; }

.comment {
  margin-bottom: 1.25em;
  vertical-align: top;
  display: table; }

[dir="rtl"] .comment {
  direction: rtl; }

.comment__meta {
  padding: 0 30px 0 0;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .comment__meta {
  padding: 0 0 0 30px; }

.comment__attribution img {
  border: 1px solid #dee2e6; }

/* This is required to win over specificity of .field--type-image img */
.comment .field--name-user-picture img {
  margin: 0; }

.comment__author .username {
  white-space: nowrap; }

.comment__author {
  margin: 4px 0;
  line-height: 1.2; }

.comment__time,
.comment__permalink {
  margin-bottom: 4px;
  color: #68696b;
  font-size: 0.875rem;
  line-height: 1.2;
  padding: 0 0.5em 0 0;
  /* LTR */ }

[dir="rtl"] .comment__time,
.comment__permalink {
  padding: 0 0 0 0.5em; }

.comment__permalink {
  font-size: 0.875rem;
  line-height: 1.2; }

.comment__content:before {
  content: '';
  position: absolute;
  right: 100%;
  /* LTR */
  top: 1.25em;
  border-top: 1.25em solid transparent;
  border-right: 1.25em solid #dee2e6;
  /* LTR */
  border-bottom: 1.25em solid transparent; }

[dir="rtl"] .comment__content:before {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 1.25em solid #dee2e6; }

.comment__content:after {
  content: '';
  position: absolute;
  right: 100%;
  /* LTR */
  top: 1.25em;
  border-top: 1.25em solid transparent;
  border-right: 1.25em solid #fff;
  /* LTR */
  border-bottom: 1.25em solid transparent;
  margin-right: -1px;
  /* LTR */ }

[dir="rtl"] .comment__content:after {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: 1.25em solid #fff;
  margin-right: 0;
  margin-left: -1px; }

.comment__content h3 {
  margin-top: 0.94em;
  margin-bottom: 0.45em;
  font-size: 1.25rem; }

.comment__content nav {
  padding-top: 1px; }

.indented {
  margin-left: 40px;
  /* LTR */ }

[dir="rtl"] .indented {
  margin-right: 40px;
  margin-left: 0; }

.comment .links {
  padding: 0 0 0.25em 0; }

.comment .links-inline span {
  padding: 0 0.5em 0 0;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .comment .links-inline span {
  padding: 0 0 0 0.5em; }

.comment--unpublished {
  margin-right: 5px;
  /* LTR */
  padding: 5px 2px 5px 5px;
  /* LTR */
  background: #7ecfff; }

[dir="rtl"] .comment--unpublished {
  margin-left: 5px;
  margin-right: 0;
  padding: 5px 5px 5px 2px; }

/**
 * @todo: unpublished nodes have class .node--unpublished.
 * change this to .comment--unpublished.
 */
.unpublished .comment-text .comment-arrow {
  border-left: 1px solid #7ecfff;
  border-right: 1px solid #7ecfff; }

.unpublished {
  padding: 1.25em 1.25em 0; }

.comment-footer {
  display: table-row; }

.comment--unpublished .comment__text:after,
.node--unpublished .comment__text:after {
  border-right-color: #7ecfff;
  /* LTR */ }

[dir="rtl"] .comment--unpublished .comment__content:after,
[dir="rtl"] .node--unpublished .comment__content:after {
  border-left-color: #7ecfff; }

.views-field-variations > span > div {
  display: flex;
  align-items: center; }

.views-field-variations > span > div > div {
  display: inline-block; }

.views-field-variations {
  margin-bottom: 0; }
  .views-field-variations .field--name-price {
    display: inline-block;
    width: 70px;
    text-align: right;
    padding-bottom: 1.2rem; }
  .views-field-variations .field--type-entity-reference {
    padding-left: 1rem; }
  .views-field-variations .commerce-order-item-variation-cart-form-form {
    display: flex;
    max-width: 100%; }
    .views-field-variations .commerce-order-item-variation-cart-form-form .field--name-purchased-entity {
      padding: 0;
      margin: 0; }
    .views-field-variations .commerce-order-item-variation-cart-form-form .field--name-quantity {
      display: inline-block;
      margin-bottom: 0;
      padding: 0; }
      .views-field-variations .commerce-order-item-variation-cart-form-form .field--name-quantity label {
        display: none; }
      .views-field-variations .commerce-order-item-variation-cart-form-form .field--name-quantity input {
        width: 50px; }
    .views-field-variations .commerce-order-item-variation-cart-form-form .form-actions {
      display: inline-block;
      margin-bottom: 0;
      padding: 0; }

#edit-shipping-information .col-auto,
#edit-payment-information-billing-information .col-auto,
#edit-payment-information-billing-information.col-auto,
#edit-order-summary .col-auto,
#edit-order-summary.col-auto {
  padding: 0; }

#edit-shipping-information #edit-shipping-information-shipping-profile-field-ruc-wrapper {
  display: none; }

.view-id-listado_de_productos .views-row > div {
  display: inline-block; }

.view-id-listado_de_productos .views-row {
  border-bottom: 1px solid #ced4da;
  margin-top: 0.5rem;
  display: flex; }
  .view-id-listado_de_productos .views-row .views-field-title {
    width: 320px;
    display: flex;
    align-items: center;
    color: #005283; }

.cart-block--contents {
  background: #f02500;
  color: #fff;
  font-size: 0.75rem;
  width: auto; }
  .cart-block--contents .cart-block--contents__items {
    max-height: inherit;
    overflow-y: hidden;
    overflow-x: hidden; }
    .cart-block--contents .cart-block--contents__items table {
      color: #fff; }
      .cart-block--contents .cart-block--contents__items table thead.thead-light {
        background: #f02500;
        color: #fff; }
      .cart-block--contents .cart-block--contents__items table tr:hover {
        color: #fff; }

.cart--cart-offcanvas {
  background: #f02500;
  color: #fff;
  font-size: 0.75rem; }
  .cart--cart-offcanvas .cart-block--offcanvas-contents__links {
    padding: 0.5rem 1rem; }
    .cart--cart-offcanvas .cart-block--offcanvas-contents__links a {
      color: #fff;
      text-decoration: none; }
    .cart--cart-offcanvas .cart-block--offcanvas-contents__links a:hover {
      color: #ffeeeb; }
  .cart--cart-offcanvas .close {
    color: #fff;
    padding: 0.25rem; }
  .cart--cart-offcanvas table {
    color: #fff; }
    .cart--cart-offcanvas table .btn {
      color: #fff; }
    .cart--cart-offcanvas table td.cart-block--offcanvas-cart-table__title,
    .cart--cart-offcanvas table td.cart-block--offcanvas-cart-table__price {
      padding: 0.5rem 0.25rem !important; }
    .cart--cart-offcanvas table td.cart-block--offcanvas-cart-table__quantity {
      padding: 0.25rem !important; }
    .cart--cart-offcanvas table .btn {
      color: #fff;
      font-size: 0.75rem; }
    .cart--cart-offcanvas table .btn:hover {
      color: #ffeeeb; }
    .cart--cart-offcanvas table tfoot .btn {
      background: transparent;
      color: white;
      border-color: white;
      font-size: 0.75rem;
      margin-top: 0.5rem; }
    .cart--cart-offcanvas table tfoot .btn:hover {
      background: #ff4e2e; }

/**
 * @file
 * Contextual links styles for Bootstrap Barrio.
 */
.contextual-links a {
  border-bottom: none;
  text-shadow: 0 0 0; }

/**
 * @file
 * Collapsible details.
 *
 * @see collapse.js
 * @see http://nicolasgallagher.com/css-background-image-hacks/
 */
details {
  border: 1px solid #dee2e6;
  margin-top: 1rem;
  margin-bottom: 1rem; }

details > .details-wrapper {
  padding: 0.5em 1.5em; }

/* @todo Regression: The summary of uncollapsible details are no longer
     vertically aligned with the .details-wrapper in browsers without native
     details support. */
summary {
  cursor: pointer;
  padding: 0.2em 0.5em; }

/**
 * @file
 * Visual styles for Bartik's dropbutton component.
 */
.js .dropbutton-wrapper .dropbutton-widget {
  /* This is required to win over specifity of .js td .dropbutton-widget */
  position: relative; }

.js .dropbutton-widget {
  border: 1px solid;
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2;
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e8e8e8);
  background-image: linear-gradient(to bottom, #f3f3f3, #e8e8e8);
  color: #3a3a3a;
  cursor: pointer;
  text-align: center;
  margin: 0.125em 0;
  border-radius: 1em;
  overflow: hidden; }

.js .dropbutton-widget:hover {
  border-color: #e4e4e4 #d2d2d2 #b4b4b4 #d2d2d2; }

.js .dropbutton-widget .button {
  border: none;
  margin: 0;
  padding: 0.32em 1em;
  background: transparent none; }

.js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-right: 0;
  /* LTR */ }

[dir="rtl"].js .dropbutton-multiple .dropbutton-widget .dropbutton-action a {
  margin-left: 0; }

.js .dropbutton .secondary-action {
  border-top-color: #ccc; }

.js .dropbutton-toggle button {
  background-color: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #d2d2d2);
  background-image: linear-gradient(to bottom, #e8e8e8, #d2d2d2); }

.js .dropbutton-toggle .dropbutton-arrow:hover {
  background: #ccc; }

.js .dropbutton a {
  color: #3a3a3a;
  border-bottom: 0 none; }

.js .dropbutton .dropbutton-action:hover,
.js .dropbutton a:hover {
  background: #dedede;
  border-bottom: 0 none; }

/**
 * @file
 * General styles for dropbuttons.
 */
.js .dropbutton-widget {
  background-color: white;
  border: 1px solid #cccccc; }

.js .dropbutton-widget:hover {
  border-color: #b8b8b8; }

.dropbutton .dropbutton-action > * {
  padding: 0.1em 0.5em;
  white-space: nowrap; }

.dropbutton .secondary-action {
  border-top: 1px solid #e8e8e8; }

.dropbutton-multiple .dropbutton {
  border-right: 1px solid #e8e8e8;
  /* LTR */ }

[dir="rtl"] .dropbutton-multiple .dropbutton {
  border-left: 1px solid #e8e8e8;
  border-right: 0 none; }

.dropbutton-multiple .dropbutton .dropbutton-action > * {
  margin-right: 0.25em;
  /* LTR */ }

[dir="rtl"] .dropbutton-multiple .dropbutton .dropbutton-action > * {
  margin-left: 0.25em;
  margin-right: 0; }

.js td .dropbutton-multiple {
  padding-right: 10em;
  margin-right: 2em;
  max-width: 100%; }

.js td .dropbutton-wrapper {
  min-height: 2em; }

.js .dropbutton-wrapper,
.js .dropbutton-widget {
  display: block;
  position: relative; }

.js td .dropbutton-widget {
  position: absolute; }

/**
 * @file
 * Feed icon styles for Bootstrap Barrio.
 */
.feed-icon {
  border-bottom: none;
  display: inline-block;
  padding: 1rem 0 0 0; }

/**
 * @file
 * Default style for file module.
 */
/* File icons.*/
.file {
  padding-left: 1.5rem;
  /* LTR */
  display: inline-block;
  min-height: 1rem;
  background-repeat: no-repeat;
  background-position: left center;
  /* LTR */ }

[dir="rtl"] .file {
  padding-left: inherit;
  padding-right: 1.5rem;
  background-position: right center; }

.file--general,
.file--application-octet-stream {
  background-image: url(../images/icons/application-octet-stream.png); }

.file--package-x-generic {
  background-image: url(../images/icons/package-x-generic.png); }

.file--x-office-spreadsheet {
  background-image: url(../images/icons/x-office-spreadsheet.png); }

.file--x-office-document {
  background-image: url(../images/icons/x-office-document.png); }

.file--x-office-presentation {
  background-image: url(../images/icons/x-office-presentation.png); }

.file--text-x-script {
  background-image: url(../images/icons/text-x-script.png); }

.file--text-html {
  background-image: url(../images/icons/text-html.png); }

.file--text-plain {
  background-image: url(../images/icons/text-plain.png); }

.file--application-pdf {
  background-image: url(../images/icons/application-pdf.png); }

.file--application-x-executable {
  background-image: url(../images/icons/application-x-executable.png); }

.file--audio {
  background-image: url(../images/icons/audio-x-generic.png); }

.file--video {
  background-image: url(../images/icons/video-x-generic.png); }

.file--text {
  background-image: url(../images/icons/text-x-generic.png); }

.file--image {
  background-image: url(../images/icons/image-x-generic.png); }

/**
 * @file
 * Visual styles for Bootstrap Barrio's form components.
 */
.form-required::after {
  content: '';
  vertical-align: super;
  display: inline-block;
  background-image: url(../../images/required.svg);
  background-repeat: no-repeat;
  background-size: calc(1.5em + 0.75rem + 2px)/2 calc(1.5em + 0.75rem + 2px)/2;
  width: calc(1.5em + 0.75rem + 2px)/2;
  height: calc(1.5em + 0.75rem + 2px)/2;
  margin: 0 0.3em; }

form .filter-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem; }

form .form-type-textarea {
  margin-bottom: 0; }

/* ------------------ Header ------------------ */
#navbar-top {
  padding: 0 1rem; }

#navbar-top.navbar-dark .nav-link:hover,
#navbar-top.navbar-dark .nav-link:focus {
  color: rgba(255, 255, 255, 0.75); }

#navbar-top.navbar-dark .nav-link {
  color: rgba(255, 255, 255, 0.5); }

#navbar-top.navbar-light .nav-link:hover,
#navbar-top.navbar-light .nav-link:focus {
  color: rgba(0, 0, 0, 0.7); }

#navbar-top.navbar-light .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-brand .site-logo {
  margin-right: 1rem; }

@media (max-width: 33.9em) {
  .navbar-toggleable-xs .navbar-nav .nav-item + .nav-item,
  .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
    margin-left: 0;
    float: none; } }

@media (max-width: 47.9em) {
  .navbar-toggleable-sm .navbar-nav .nav-item + .nav-item,
  .navbar-toggleable-xs.collapse.in .navbar-nav .nav-item {
    margin-left: 0;
    float: none; } }

/**
 * @file
 * Styles for the help block.
 */
.block-help {
  border: 1px solid #dee2e6;
  padding: 0 1.5rem;
  margin-bottom: 2rem; }

/**
 * @file
 * Visual styles for icons.
 */
.icon-help {
  background: url(/../../images/help.png) 0 50% no-repeat;
  /* LTR */
  padding: 1px 0 1px 1.5rem;
  /* LTR */ }

[dir="rtl"] .icon-help {
  background-position: 100% 50%;
  padding: 1px 1.5rem 1px 0; }

.feed-icon {
  background: url(../images/feed.svg) no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  display: block;
  width: 1rem;
  height: 1rem; }

/**
 * @file
 * Styles for Bootstrap Barrio's image buttons.
 */
.image-button.is-disabled:hover,
.image-button.is-disabled:active,
.image-button.is-disabled:focus,
.image-button.is-disabled {
  background: transparent;
  opacity: 0.5;
  cursor: default; }

/**
 * @file
 * Basic styling for comment module.
 */
/**
 * Indent threaded comments.
 */
.indented {
  margin-left: 1.5rem;
  /* LTR */ }

[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 1.5rem; }

/**
 * @file
 * Visual styles for Bootstrap Barrio's item list.
 */
.item-list ul {
  list-style: none;
  margin: 0 0 0.75rem 0;
  padding: 0;
  /* LTR */ }

[dir="rtl"] .item-list ul {
  padding: 0; }

.item-list ul li {
  /* This is needed to reset styles in item-list.theme.css */
  margin: 0;
  padding: 0.75rem 1.25rem 0 0;
  /* LTR */ }

[dir="rtl"] .item-list ul li {
  /* This is needed to reset [dir="rtl"] styles in item-list.theme.css */
  margin: 0;
  padding: 0.75rem 0 0 1.25rem; }

.item-list .item-list__comma-list,
.item-list .item-list__comma-list li,
[dir="rtl"] .item-list .item-list__comma-list,
[dir="rtl"] .item-list .item-list__comma-list li {
  padding: 0; }

/**
 * @file
 * List group.
 * A list of links, grouped together.
 */
.list-group__link {
  border-top: 1px solid #dee2e6;
  padding: 0.75rem 0 0; }

.list-group__description {
  margin: 0 0 0.75rem; }

/**
 * @file
 * Visual styles for Bootstrap Barrio's node component.
 */
.node__content {
  font-size: 1rem;
  margin-top: 1rem; }

/* View mode teaser styles. */
.node--view-mode-teaser {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.node--view-mode-teaser h2 {
  margin-top: 0;
  padding-top: 0.5em; }

.node--view-mode-teaser.node--sticky {
  background: #f9f9f9;
  background: rgba(0, 0, 0, 0.024);
  border: 1px solid #dee2e6;
  padding: 0 1rem 1rem; }

.node--view-mode-teaser .node__content {
  clear: none;
  font-size: 1rem;
  line-height: 1.5; }

/* Node metadata styles. */
.node__meta {
  font-size: 0.875rem;
  margin-bottom: -5px; }

.node__meta .field--name-field-user-picture img {
  float: left;
  /* LTR */
  margin: 1px 1rem 0 0;
  /* LTR */ }

[dir="rtl"] .node__meta .field--name-field-user-picture img {
  float: right;
  margin-left: 1rem;
  margin-right: 0; }

/* Node links styles. */
.node__links {
  text-align: right;
  /* LTR */
  font-size: 1rem; }

[dir="rtl"] .node__links {
  text-align: left; }

/* Unpublished node styles. */
.node--unpublished {
  padding: 1rem 1rem 0; }

.node--unpublished .comment-text .comment-arrow {
  border-left: 1px solid #fff4f4;
  border-right: 1px solid #fff4f4; }

.node-preview-container {
  background: #d1e8f5;
  background-image: -webkit-linear-gradient(top, #d1e8f5, #d3e8f4);
  background-image: linear-gradient(to bottom, #d1e8f5, #d3e8f4);
  font-family: Arial, sans-serif;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3333);
  position: fixed;
  z-index: 499;
  width: 100%;
  padding: 10px; }

.node-preview-backlink {
  background-color: #419ff1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #419ff1, #1076d5);
  /* LTR */
  border: 1px solid #0048c8;
  border-radius: .4em;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
  color: #fff;
  font-size: 0.9em;
  line-height: normal;
  margin: 0;
  padding: 4px 1em 4px 0.6em;
  /* LTR */
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.5); }

[dir="rtl"] .node-preview-backlink {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #419ff1, #1076d5);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #419ff1, #1076d5);
  padding: 4px 0.6em 4px 1em;
  float: right; }

.node-preview-backlink:focus,
.node-preview-backlink:hover {
  background-color: #419cf1;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef);
  /* LTR */
  border: 1px solid #0048c8;
  text-decoration: none;
  color: #fff; }

[dir="rtl"] .node-preview-backlink:focus,
[dir="rtl"] .node-preview-backlink:hover {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #59abf3, #2a90ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #59abf3, #2a90ef); }

.node-preview-backlink:active {
  background-color: #0e69be;
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-left.svg) left no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef);
  /* LTR */
  border: 1px solid #0048c8;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25); }

[dir="rtl"] .node-preview-backlink:active {
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, -webkit-linear-gradient(top, #0e69be, #2a93ef);
  background: url(/core/misc/icons/000000/chevron-right.svg) right no-repeat, linear-gradient(to bottom, #0e69be, #2a93ef); }

.node-preview-backlink::before {
  content: '';
  width: 10px;
  display: inline-block; }

/**
 * @file
 * Styles for Bootstrap Barrio page.
 */
/**
 * @file
 * The visual styles for Bootstrap Barrio's search form(s).
 */
input[type="search"] {
  box-sizing: border-box; }

header #search-block-form {
  padding: 5px 15px;
  flex-flow: row wrap; }

#search-block-form {
  display: flex; }

/**
 * @file
 * Visual styles for the Shortcut action link in Bootstrap Barrio.
 */
.shortcut-wrapper {
  margin: 2.2em 0 1.1em 0;
  /* Same as usual .page-title margin. */ }

.shortcut-wrapper .page-title {
  float: left;
  /* LTR */
  margin: 0; }

[dir="rtl"] .shortcut-wrapper .page-title {
  float: right; }

.shortcut-action {
  border-bottom: none;
  margin-left: 0.5em;
  /* LTR */
  padding-top: 0.35em; }

[dir="rtl"] .shortcut-action {
  margin-left: 0;
  margin-right: 0.5em; }

.shortcut-action:hover,
.shortcut-action:active,
.shortcut-action:focus {
  border-bottom: none; }

/* ------------------ Sidebar ----------------- */
/*
.sidebar {
    .block {
        border-style: solid;
        border-width: $border-width;
        padding: $spacer $spacer;
        margin: 0 0 $spacer;
        .content {
            font-size: $font-size-base;
            line-height: $line-height-sm;
        }
    }
    h2 {
        margin: 0 0 ($spacer * .5);
        border-bottom: $border-width solid $border-color;
        padding-bottom: 5px;
        text-shadow: 0 $border-width 0 $white;
        font-size: $font-size-lg;
        line-height: $headings-line-height;
    }
    tbody {
        border: none;
    }
    tr.even,
    tr.odd {
        background: none;
        border-bottom: $border-width solid $border-color;
    }
    nav ul.nav {
        margin: 0 -($spacer);
    }
}
*/
/**
 * @file
 * Footer Styling.
 */
.site-footer {
  padding: 1rem 0; }
  .site-footer .layout-container {
    padding: 0 1rem; }

/* Footer Top Styling. */
.site-footer__top .content {
  margin-top: 0; }

/* Footer Bottom Styling. */
.site-footer__bottom .block {
  margin: 0.5rem 0; }

.site-footer__bottom .content {
  padding: 0.5rem 0;
  margin-top: 0; }

/**
 * @file
 * Styles for the skip link.
 */
.skip-link {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 50;
  background: #495057;
  font-size: 1rem;
  line-height: 1.5;
  padding: 1px 1rem 2px;
  border-radius: 0 0 0.3rem 0.3rem;
  border-bottom-width: 0;
  outline: 0; }

.skip-link.visually-hidden.focusable:focus {
  position: absolute !important;
  color: #fff; }

/**
 * @file
 * Styles for Bootstrap Barrio's tables.
 */
.sticky-header thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.sticky-header th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

/**
 * @file
 * Visual styles for table drag.
 */
tr.drag {
  background-color: #fffff0; }

tr.drag-previous {
  background-color: #ffd; }

body div.tabledrag-changed-warning {
  margin-bottom: 0.5rem; }

/**
 * @file
 * Table select behavior.
 *
 * @see tableselect.js
 */
tr.selected td {
  background: #ffc; }

td.checkbox,
th.checkbox {
  text-align: center; }

[dir="rtl"] td.checkbox,
[dir="rtl"] th.checkbox {
  /* This is required to win over specificity of [dir="rtl"] td */
  text-align: center; }

/**
 * @file
 * Table sort indicator.
 */
th.is-active img {
  display: inline; }

td.is-active {
  background-color: #dee2e6; }

/**
 * @file
 * Tablesort indicator styles.
 */
.tablesort {
  width: 14px;
  height: 14px;
  vertical-align: top;
  margin: 1px 0 0 5px; }

.tablesort--asc {
  background-image: url(/core/misc/icons/ffffff/twistie-down.svg); }

.tablesort--desc {
  background-image: url(/core/misc/icons/ffffff/twistie-up.svg); }

/**
 * @file
 * Theme styling for tabs.
 */
nav.tabs {
  margin-bottom: 1rem; }

/**
 * @file
 * Visual styles for a resizable textarea.
 */
.form-textarea-wrapper textarea {
  display: block;
  margin: 0;
  width: 100%;
  box-sizing: border-box; }

/**
 * @file
 * Visual styles for Bartik's ui-widget component.
 */
/**
 * @file
 * Styles for Bartik's modal windows
 */
.ui-widget-overlay {
  background: #000;
  opacity: 0.7; }

.ui-dialog {
  border-radius: 0; }

.ui-dialog input,
.ui-dialog select,
.ui-dialog textarea {
  font-size: 1rem; }

.ui-dialog .button {
  background-color: #fff;
  background-image: -webkit-linear-gradient(top, #f3f3f3, #e8e8e8);
  background-image: linear-gradient(to bottom, #f3f3f3, #e8e8e8);
  border: 1px solid #e4e4e4;
  border-bottom-color: #b4b4b4;
  border-left-color: #d2d2d2;
  border-right-color: #d2d2d2;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 1rem;
  font-weight: normal;
  text-align: center;
  padding: 0.250em 1.063em;
  border-radius: 1em; }

/**
 * @file
 * Theme styling for user module.
 */
/* Visual styling for the Password strength indicator */
.password-strength__meter {
  margin-top: 0.5em;
  background-color: #e9ecef; }

.password-strength__indicator {
  background-color: #28a745;
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out; }

.password-strength__indicator.is-weak {
  background-color: #dc3545; }

.password-strength__indicator.is-fair {
  background-color: #ffc107; }

.password-strength__indicator.is-good {
  background-color: #17a2b8; }

.password-strength__indicator.is-strong {
  background-color: #28a745; }

.password-confirm,
.password-field,
.password-strength,
.password-confirm-match {
  width: 55%; }

.password-suggestions {
  padding: 0.2em 0.5em;
  margin: 0.7em 0;
  max-width: 34.7em;
  border: 1px solid #dee2e6; }

.password-suggestions ul {
  margin-bottom: 0; }

.confirm-parent,
.password-parent {
  clear: left;
  /* LTR */
  margin: 0;
  max-width: 33em;
  overflow: hidden; }

[dir="rtl"] .confirm-parent,
[dir="rtl"] .password-parent {
  clear: right; }

/* Styling for the status indicator of the passwords match test.  */
.password-confirm .ok {
  color: #28a745;
  font-weight: bold; }

.password-confirm .error {
  color: #dc3545;
  font-weight: bold; }

/* Login page stype */
.page-user-login main .region-content,
.page-user-password main .region-content {
  max-width: 400px;
  background: white;
  padding: 1rem 1rem;
  margin: 0 auto;
  border-radius: 0.3rem; }

/**
 * @file
 * Vertical tabs component.
 */
/* Vertical Tabs Reset */
.vertical-tabs__menu {
  margin: -1px 0 -1px;
  /* LTR */
  padding: 0; }

[dir="rtl"] .vertical-tabs__menu {
  margin-left: 0;
  margin-right: -1em;
  /* This is required to win specificity over [dir="rtl"] .region-content ul */
  padding: 0; }

/**
 * @file
 * Vertical Tabs.
 */
.vertical-tabs {
  margin: 0;
  /* LTR */
  border: 1px solid #dee2e6;
  display: flex;
  flex-direction: row;
  font-size: 0.875rem; }

[dir="rtl"] .vertical-tabs {
  flex-direction: row-reverse;
  margin-left: 0;
  margin-right: 0; }

.vertical-tabs__menu {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* LTR */
  width: 15em;
  margin: 0;
  /* LTR */
  padding: 0;
  border-top: 1px solid #dee2e6;
  list-style: none; }

[dir="rtl"] .vertical-tabs__menu {
  margin-left: 0;
  margin-right: -15em; }

.vertical-tabs__pane {
  margin: 0;
  border: 0; }

.vertical-tabs__pane > summary {
  display: none; }

/* Layout of each tab. */
.vertical-tabs__menu-item {
  border: 1px solid #dee2e6;
  border-top: 0;
  background: #eee; }

.vertical-tabs__menu-item a {
  display: block;
  padding: 0.5em 0.6em;
  text-decoration: none; }

.vertical-tabs__menu-item a:focus .vertical-tabs__menu-item-title,
.vertical-tabs__menu-item a:active .vertical-tabs__menu-item-title,
.vertical-tabs__menu-item a:hover .vertical-tabs__menu-item-title {
  text-decoration: underline; }

.vertical-tabs__menu-item a:hover {
  outline: 1px dotted; }

.vertical-tabs__menu-item.is-selected {
  border-right-width: 0;
  /* LTR */
  background-color: #fff; }

[dir="rtl"] .vertical-tabs__menu-item.is-selected {
  border-left-width: 0;
  border-right-width: 1px; }

.vertical-tabs__menu-item.is-selected .vertical-tabs__menu-item-title {
  color: #000; }

.vertical-tabs__menu-item-summary {
  display: block;
  margin-bottom: 0;
  line-height: normal; }

/**
 * @file
 * Styling for Views in Bartik.
 */
/* Tab styles */
.views-displays .tabs .open > a {
  border-radius: 0.3rem 0.3rem 0 0; }

.views-displays .tabs .open > a:hover,
.views-displays .tabs .open > a:focus {
  color: #0079C0; }

.views-displays .secondary .form-submit {
  font-size: 0.875rem; }

/* Tabs action list styles */
.views-displays .tabs .action-list {
  padding: 0; }

/* Contextual filter options styles */
.views-filterable-options .filterable-option:nth-of-type(even) .form-type-checkbox {
  background-color: #F9F9F9; }

/* Views action dropbutton styles */
.views-ui-display-tab-actions .dropbutton .form-submit {
  color: #005283; }

.views-ui-display-tab-actions .dropbutton .form-submit:hover,
.views-ui-display-tab-actions .dropbutton .form-submit:focus {
  color: #0079C0; }

.path-frontpage .highlighted {
  position: relative;
  z-index: 1; }
  .path-frontpage .highlighted .administra-gestor-botonera-superior {
    float: right;
    position: relative;
    top: 20px;
    height: 0px;
    right: 140px; }
    .path-frontpage .highlighted .administra-gestor-botonera-superior .btn-info {
      margin-left: 20px;
      color: #fff; }

.administra-gestor-botonera-superior {
  float: right;
  position: relative;
  top: -70px;
  height: 0px;
  right: 140px; }
  .administra-gestor-botonera-superior .btn-info {
    margin-left: 20px;
    color: #fff; }

header {
  background-color: #007bc4; }

/* TOP NAVBAR */
.path-frontpage header .top-navbar-logos {
  margin: 0px 0px 50px 0px; }

header .top-navbar-logos {
  position: relative;
  display: flex;
  padding: 20px 0px 20px 0px;
  margin: 0px 0px 20px 0px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #fff; }

header .top-navbar-logos .logo {
  display: inline-block;
  width: 140px;
  float: none !important;
  margin-right: 0px; }

header .top-navbar-logos .logo img {
  height: 48px; }

.navbar-toggle {
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  float: none;
  padding: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background: transparent;
  outline: 0px;
  border: 0px; }

.navbar-toggle:focus,
.navbar-search:focus {
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: -2px; }

.navbar-search {
  right: 42px;
  width: 30px;
  background: transparent;
  outline: 0px;
  border: 0px; }

.navbar-search img {
  width: 100%;
  max-width: 100%;
  display: inline-block; }

.navbar-close {
  top: -3px;
  transform: none;
  background: transparent;
  outline: 0px;
  border: 0px; }

/* NAVBAR */
header .navbar-header {
  padding: 25px 30px 25px 30px !important;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px !important;
  margin-right: 0px !important; }

header #main-menu-collapse {
  background-color: #002B4A;
  width: 100%;
  max-width: 460px;
  height: 100%;
  margin-top: 0px;
  height: 100vh !important;
  position: fixed !important;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -webkit-transition: all 0.35s !important;
  -o-transition: all 0.35s !important;
  transition: all 0.35s !important;
  -moz-transition: all 0.35s !important;
  -ms-transition: all 0.35s !important; }

header #main-menu-collapse.show {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  -moz-transform: translateX(0);
  box-shadow: -8px 12px 15px rgba(0, 0, 0, 0.3); }

header .navbar-header .menu-superior,
header .navbar-header .menu-secundario {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 25px; }

header .navbar-header .menu-superior .top-navbar-idioma {
  display: block; }

header .navbar-header .menu-superior .top-navbar-idioma nav {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

header .navbar-header .menu-superior .top-navbar-idioma nav span {
  display: inline-block;
  padding: 0px; }

header .navbar-header .menu-superior .top-navbar-idioma nav span + span:before {
  content: "|";
  padding: 0px 8px 0px 8px;
  font-size: .7rem;
  color: #fff; }

header .navbar-header .menu-superior .top-navbar-idioma nav span + span:before {
  content: "|";
  padding: 0px 8px 0px 8px;
  font-size: .7rem;
  color: #fff; }

header .navbar-header .menu-superior .top-navbar-idioma nav span a {
  font-size: .7rem;
  padding: 4px 8px 2px 8px;
  line-height: 20px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none; }

header .navbar-header .menu-superior .top-navbar-idioma nav span.is-active a,
header .navbar-header .menu-superior .top-navbar-idioma nav span a:hover {
  background: #007bc4;
  font-weight: 700;
  font-size: .65rem; }

header .navbar-header .menu-superior .top-navbar-idioma nav span a:hover,
header .navbar-header .menu-superior .top-navbar-idioma nav span a:focus {
  text-decoration: none; }

/* BUSCADOR */
header .block-search .container-inline > div {
  width: 100%; }

header .block-search .container-inline form.search-form {
  padding: 5px 0 !important;
  position: relative; }

header .block-search .container-inline .js-form-type-search {
  flex-basis: 100%;
  margin-bottom: 0 !important;
  padding-left: 5px;
  padding-right: 5px; }
  header .block-search .container-inline .js-form-type-search input {
    background-color: transparent;
    color: #FFF;
    padding-left: 30px;
    border: none;
    box-shadow: none; }

header .block-search .container-inline div[data-drupal-selector="edit-actions"] {
  position: absolute;
  top: 0;
  margin: 0 !important;
  padding-left: 5px;
  padding-right: 5px; }
  header .block-search .container-inline div[data-drupal-selector="edit-actions"] button {
    width: 20px;
    height: 35px;
    background-color: transparent;
    border-color: transparent;
    background-image: url("../images/logos/ico_buscador.svg");
    background-repeat: no-repeat;
    background-position: 0px 10px;
    background-size: 20px; }

/* Menú */
header {
  /* Dropdown */ }
  header .navbar-header nav {
    display: block;
    width: 100%;
    order: 1; }
  header .navbar-header nav ul.nav {
    margin: 0px;
    display: block;
    width: 100%;
    float: none; }
  header .navbar-header .nav > li {
    padding: 10px 0px;
    display: block;
    width: 100%;
    float: none;
    border-bottom: 1px solid #fff !important; }
  header .navbar-header .nav > li:last-child {
    border-bottom: none !important; }
  header .navbar-header .nav > li > a {
    position: relative;
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 1.1rem;
    padding: 6px 13px 4px 0px;
    color: #fff;
    margin: 0px;
    border: 0px;
    transition: .3s ease; }
  header .navbar-header .nav > li > a:hover,
  header .navbar-header .nav > li > a:focus {
    color: #BFDEF0; }
  header .navbar-header .nav li .dropdown-toggle::after {
    margin-left: 0px;
    vertical-align: inherit;
    border: 0px;
    position: absolute;
    display: inline-block;
    border: 0px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 10px;
    background-image: url("../images/icons/flechamenusupinv.svg");
    background-size: 18px 10px;
    background-repeat: no-repeat;
    content: ""; }
  header .navbar-header .nav li.show .dropdown-toggle::after {
    transform: rotate(180deg);
    top: 40%; }
  header .nav > li > a:hover, header .nav > li > a:focus {
    background-color: transparent;
    border: 0px !important; }
  header .navbar-header .nav > li .dropdown-menu {
    display: none;
    background-color: transparent;
    float: none;
    box-shadow: none;
    position: relative !important;
    left: 0px;
    padding: 12px 0px 12px 24px;
    border: 0px !important;
    inset: none !important;
    margin: 0px;
    transform: none !important; }
  header .navbar-header .nav > li.show .dropdown-menu {
    display: block; }
  header .navbar-header .nav > li .dropdown-menu li {
    width: 100% !important;
    float: none !important;
    padding: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px; }
  header .navbar-header .nav .dropdown-menu li:hover,
  header .navbar-header .nav .dropdown-menu li:focus {
    background-color: transparent; }
  header .navbar-header .nav .dropdown-menu li a {
    background: transparent;
    display: block;
    border: 0px !important;
    padding: 6px 10px 4px 10px !important;
    font-size: .9rem !important;
    color: #fff !important;
    white-space: pre-wrap;
    text-decoration: none;
    transition: .3s ease; }
  header .navbar-header .nav .dropdown-menu li a:hover,
  header .navbar-header .nav .dropdown-menu li a:focus {
    color: #BFDEF0 !important; }
  header .dropdown-item.active, header .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important; }
  header .navbar-header .menu--menu-secundario {
    margin-top: 30px;
    border-top: 1px dashed #fff; }
    header .navbar-header .menu--menu-secundario .nav {
      margin-top: 20px; }
      header .navbar-header .menu--menu-secundario .nav li {
        border-bottom: none !important;
        background: transparent;
        padding-bottom: 0px; }
        header .navbar-header .menu--menu-secundario .nav li a {
          display: block;
          border: 0px !important;
          padding: 6px 10px 4px 0px !important;
          font-size: .9rem !important;
          color: #fff !important;
          white-space: pre-wrap;
          text-decoration: none;
          -webkit-transition: .3s ease;
          -o-transition: .3s ease;
          transition: .3s ease; }

/* Search */
header .navbar-header .search-block-form {
  display: inline-block;
  padding: 0px 0px;
  order: 0;
  width: 100%; }

header .navbar-header .search-block-form form,
header .navbar-header .search-block-form form .form-item,
header .navbar-header .search-block-form form .form-item .input-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px; }

header .navbar-header .search-block-form form,
header .navbar-header .search-block-form form .form-item,
header .navbar-header .search-block-form form .form-item .input-group {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px; }

header .navbar-header .search-block-form form .form-item .input-group label {
  display: none; }

header .navbar-header .search-block-form form .form-item .input-group {
  position: relative; }

header .navbar-header .search-block-form form .form-item .input-group input {
  height: auto;
  float: none;
  padding: 8px 12px 8px 35px;
  border-radius: 8px;
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  border: 0px solid transparent;
  box-shadow: none !important;
  transition: none !important; }

header .navbar-header .search-block-form form .form-item .input-group input::placeholder,
header .navbar-header .search-block-form form .form-item .input-group input:-ms-input-placeholder,
header .navbar-header .search-block-form form .form-item .input-group input::-ms-input-placeholder {
  font-size: 1rem !important;
  color: #fff !important;
  opacity: 1 !important; }

header .navbar-header .search-block-form form .form-item .input-group input:focus {
  box-shadow: none !important; }

header .navbar-header .search-block-form form .form-item .input-group .input-group-btn {
  width: auto;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 50%;
  z-index: 5;
  transform: translateY(-50%); }

header .navbar-header .search-block-form form .form-item .input-group .input-group-btn button {
  box-shadow: none !important;
  transition: none !important;
  background-color: inherit;
  border: 0px;
  outline: 0px;
  padding: 6px 10px 6px 4px;
  border-radius: 14px 0px 0px 14px; }

header .navbar-header .search-block-form form .form-item .input-group .input-group-btn button span {
  color: #fff;
  font-size: 1rem; }

header .navbar-header .search-block-form form .form-item .input-group .input-group-btn button span:before {
  display: inline-block;
  width: 20px;
  content: url("../images/ico_buscador.svg"); }

/* xs */
@media (max-width: 767.98px) {
  /* TOP NAVBAR */
  header .top-navbar-logos {
    position: relative;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0px 10px 0px; }
  header .top-navbar-logos .logo {
    width: 160px;
    margin: 10px 0px; } }

/* sm */
/* md */
/* lg */
/* xl */
.language-switcher-language-url span.en {
  display: none !important; }

/* Footer */
.prefooter {
  background-color: #FAFAF9;
  padding-top: 40px;
  margin-top: 40px;
  min-height: 170px;
  color: #010917; }
  .prefooter .field--name-field-logos {
    float: right; }
    .prefooter .field--name-field-logos .field__item {
      float: right;
      margin-right: 20px; }
      .prefooter .field--name-field-logos .field__item img {
        width: 150px;
        object-fit: contain;
        height: 75px; }
  .prefooter h2 {
    font-size: 18px;
    margin-bottom: 15px;
    display: block;
    font-weight: bold;
    padding-left: 0 !important; }
  .prefooter .field--name-body {
    line-height: 22px; }
    .prefooter .field--name-body p {
      margin-bottom: 5px; }
  .prefooter .field--name-field-redes-sociales-footer {
    margin-top: 28px; }
    .prefooter .field--name-field-redes-sociales-footer .field__item {
      float: left;
      width: 30px; }
      .prefooter .field--name-field-redes-sociales-footer .field__item a {
        color: #FAFAF9 !important; }
        .prefooter .field--name-field-redes-sociales-footer .field__item a i {
          color: #010917; }
          .prefooter .field--name-field-redes-sociales-footer .field__item a i:hover {
            color: #4C6B80; }

footer .footer {
  background-color: #007bc4;
  margin-top: 0 !important;
  font-size: 11px;
  width: 100%;
  padding: 20px 0;
  left: 0;
  bottom: 0;
  width: 100%; }

footer .footer .titulo {
  background-color: #CAEEF2;
  color: #00587A;
  padding: 10px 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  background-image: url(../images/ico_title_small_oscuro.png);
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-size: contain; }

footer .footer .logo-xunta a {
  display: block;
  width: 117px;
  height: 33px;
  background: url(../images/Logo_xunta_2021.svg) no-repeat;
  background-size: 110px; }

footer .footer .logo-xunta a span {
  display: none; }

footer .footer .logocc {
  height: 15px; }

footer .footer .menu--menu-footer {
  display: inline-block; }

footer .footer .links-xunta p {
  margin-bottom: 0;
  font-size: 11px;
  color: #fff; }

footer .footer .links-xunta ul {
  list-style-type: none !important;
  padding-left: 0;
  margin: 0; }

footer .footer .links-xunta ul li {
  float: left;
  margin-right: 3px;
  font-size: 11px;
  color: #fff; }

footer .footer .links-xunta ul li span {
  font-size: 11px;
  color: #fff; }

footer .footer .links-xunta ul li a {
  color: #fff;
  font-size: 11px;
  text-decoration: none; }

footer.footer .links-xunta ul li:last-child {
  display: none; }

footer .footer .links-xunta ul li a:hover {
  text-decoration: underline; }

/*
footer.footer .logo-galicia {
  display: block;
  float: right;
  width: 140px;
  height: 35px;
  background: url(../images/Logo_xacobeo_2021.svg) no-repeat;
  background-size: 140px;
}
footer.footer .logo-galicia span {
  display: none;
}*/
footer .footer .container-fluid .col-xs-12:last-child, footer .footer .container-sm .col-xs-12:last-child, footer .footer .container-md .col-xs-12:last-child, footer .footer .container-lg .col-xs-12:last-child, footer .footer .container-xl .col-xs-12:last-child {
  background-position: 15px 0px; }
  footer .footer .container-fluid .col-xs-12:last-child a, footer .footer .container-sm .col-xs-12:last-child a, footer .footer .container-md .col-xs-12:last-child a, footer .footer .container-lg .col-xs-12:last-child a, footer .footer .container-xl .col-xs-12:last-child a {
    display: block;
    width: 100%;
    height: 100%; }

@media (max-width: 768px) {
  footer .footer {
    min-height: 120px;
    margin-bottom: 0; }
  footer .footer .links-xunta {
    display: block;
    margin-top: 20px;
    top: 0;
    color: #fff; }
  footer .footer #RSSsuscripcion {
    clear: both;
    float: left;
    margin-top: 20px; }
  footer .footer .logo-galicia {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-right: 0; } }

@media (max-width: 991px) {
  footer .footer #RSSsuscripcion {
    margin-top: -15px; }
  footer .footer .logo-xunta {
    margin-bottom: 25px; }
  footer .footer .logo-galicia {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-right: 0; } }

@media (min-width: 993px) {
  a.div-logo-galicia {
    display: none !important; } }

@media (max-width: 992px) {
  footer .footer, footer .footer .logo-xunta {
    margin-bottom: 0; }
  footer .footer .logo-galicia {
    display: none; }
  footer .footer a.div-logo-galicia {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 90px;
    height: 30px;
    margin-right: 15px;
    background: url(../images/logo-galicia.svg) no-repeat;
    background-size: 90px; }
  footer .footer a.div-logo-galicia span {
    display: none; }
  footer .footer .links-xunta {
    display: block;
    margin-top: 20px;
    top: 0;
    color: #fff; } }

/* xs */
@media (max-width: 767.98px) {
  .prefooter h2 {
    text-align: center; }
  .prefooter .field--name-body {
    text-align: center; }
    .prefooter .field--name-body strong {
      font-size: 18px;
      margin-bottom: 15px;
      display: block; }
    .prefooter .field--name-body p {
      font-size: 16px; }
  .prefooter .field--name-field-redes-sociales-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden; }
    .prefooter .field--name-field-redes-sociales-footer .field__item {
      float: none;
      width: 30px; }
  .prefooter .field--name-field-logos {
    display: flex;
    justify-content: center;
    width: 100%; }
    .prefooter .field--name-field-logos .field__item {
      float: none;
      margin-right: 20px; } }

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  .prefooter .field--name-body strong {
    font-size: 18px;
    margin-bottom: 15px;
    display: block; }
  .prefooter .field--name-body p {
    font-size: 16px; } }

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .prefooter .field--name-body {
    margin-bottom: 20px; }
    .prefooter .field--name-body strong {
      font-size: 18px;
      margin-bottom: 15px;
      display: block; }
    .prefooter .field--name-body p {
      font-size: 16px; } }

/* Paxina de administracion*/
.administra-gestor h2 {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase; }

.administra-gestor .bot {
  background: #e6e6e6;
  padding: 20px;
  color: #101010;
  margin-bottom: 40px;
  text-align: center;
  min-height: 180px;
  border-radius: 10px; }
  .administra-gestor .bot a {
    margin-bottom: 5px; }

.administra-gestor .botblock {
  height: 100px;
  background: #e6e6e6;
  padding: 20px;
  color: #101010;
  margin-bottom: 40px;
  text-align: center;
  border-radius: 10px; }

.administra-gestor .add {
  margin-right: 20px; }

.administra-gestor .admin-section-title {
  color: #ed3000;
  font-size: 28px;
  border-bottom: 1px solid #ed3000;
  width: 380px;
  margin-bottom: 20px;
  margin-top: 50px; }

.view-administra-contido {
  margin-bottom: 50px; }
  .view-administra-contido .view-header {
    margin-bottom: 50px; }
  .view-administra-contido .form-item {
    margin-right: 20px; }
  .view-administra-contido .select-wrapper,
  .view-administra-contido input {
    display: grid !important; }
  .view-administra-contido .form-actions {
    margin-top: 20px; }
    .view-administra-contido .form-actions button {
      color: #ffffff !important;
      padding: 7px 50px;
      text-decoration: none;
      background-color: #d8ae48; }
  .view-administra-contido td a {
    text-decoration: none; }

.path-page-admin .administra-gestor .row .fila:last-child {
  display: none; }

/* SITEMAP */
.path-sitemap .nav a {
  color: #007bc4; }
  .path-sitemap .nav a:hover {
    opacity: 0.6; }

/* Páxina de búsqueda */
.path-search .search-form {
  display: none; }

.path-search .main-content {
  padding: 0; }

.path-search .pagination {
  justify-content: flex-end !important; }

.path-search h2 {
  font-size: 1.5rem;
  margin-bottom: 30px;
  padding-left: 15px; }

.path-search h3 {
  font-size: 1rem; }

.path-search ol.node_search-results {
  flex-wrap: wrap;
  flex-direction: row; }
  .path-search ol.node_search-results .list-group-item {
    flex-basis: 33.33%;
    border: none !important;
    margin-bottom: 30px; }
    .path-search ol.node_search-results .list-group-item a {
      color: #010917;
      text-decoration: none;
      font-weight: bold; }
      .path-search ol.node_search-results .list-group-item a:hover {
        color: #4C6B80; }

/* Paxina de de erros 403, 404 e paxinas sin contido*/
.container-sin-resultados {
  text-align: center; }
  .container-sin-resultados .imaxe-sin-resultados {
    background-image: url(../images/icons/sin-resultados.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 100%;
    margin: 80px auto 20px;
    display: block;
    background-size: contain;
    height: 250px; }
  .container-sin-resultados h3 {
    font-size: 22px;
    font-weight: bold;
    color: #757575;
    font-size: 1.3rem; }
  .container-sin-resultados p {
    font-weight: 400;
    color: #757575;
    margin-bottom: 50px;
    font-size: .9rem; }

.post-header {
  background-color: #007bc4;
  color: #fff;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .post-header .block-system-breadcrumb-block {
    padding-top: 15px;
    margin-bottom: 32px; }
    .post-header .block-system-breadcrumb-block .breadcrumb {
      background-color: #007bc4;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: 0em;
      text-align: left; }
  .post-header a {
    color: #fff;
    text-decoration: none; }
  .post-header a:hover {
    color: #fff;
    text-decoration: underline; }

/* Información sectorial */
.block-views.block-views-blockinformacion-sectorial-enlaces-lateral-block-1 {
  background-color: #F5F9FF;
  padding: 28px; }

.block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-header {
  margin-bottom: 16px; }
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-header p {
    font-size: .9rem;
    font-weight: 900;
    color: #010917; }
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-header .may {
    font-weight: bold;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase; }

.block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content {
  padding: 0px 0px 0px 15px; }
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content .views-row {
    padding-left: 0px; }
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content .views-field-name a {
    text-decoration: none;
    color: #4C6B80;
    margin-bottom: 8px;
    display: block; }
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content .views-field-name a:hover,
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content .views-field-name a:active,
  .block-views-blockinformacion-sectorial-enlaces-lateral-block-1 .view-content .views-field-name a:focus {
    color: #375266; }

.view-taxonomy-term.view-display-id-page_2 .view-header p,
.view-listado-de-informacion-sectorial .view-header p {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #010917;
  text-decoration: none; }

.view-taxonomy-term.view-display-id-page_2 .view-content .slick-track,
.view-listado-de-informacion-sectorial .view-content .slick-track {
  margin-left: 0px;
  margin-right: 0px; }
  .view-taxonomy-term.view-display-id-page_2 .view-content .slick-track .slick-slide,
  .view-listado-de-informacion-sectorial .view-content .slick-track .slick-slide {
    margin: 0px 20px 0px 0px; }

.view-taxonomy-term.view-display-id-page_2 .view-content h3,
.view-listado-de-informacion-sectorial .view-content h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #010917;
  text-decoration: none;
  margin-bottom: 16px; }

.view-taxonomy-term.view-display-id-page_2 .view-content .views-view-grid,
.view-listado-de-informacion-sectorial .view-content .views-view-grid {
  margin-bottom: 24px;
  border-bottom: 1px solid #E6EBF2;
  padding-bottom: 16px; }
  .view-taxonomy-term.view-display-id-page_2 .view-content .views-view-grid .views-field-field-imagen-del-acceso-de-infor img,
  .view-listado-de-informacion-sectorial .view-content .views-view-grid .views-field-field-imagen-del-acceso-de-infor img {
    width: 100%;
    object-fit: cover;
    height: 120px; }
  .view-taxonomy-term.view-display-id-page_2 .view-content .views-view-grid a,
  .view-listado-de-informacion-sectorial .view-content .views-view-grid a {
    font-size: 14px;
    color: #010917;
    text-decoration: none;
    line-height: 20px;
    margin-top: 8px;
    display: inline-block; }

#block-linkdeacceso .field--name-body {
  width: 100% !important;
  padding: 28px;
  max-width: 100%; }
  #block-linkdeacceso .field--name-body a {
    text-decoration: none; }

aside nav.menu--tematicas,
aside nav.menu--menu-informacion-sectorial {
  background-color: #F5F9FF;
  padding: 28px !important;
  margin: 0px; }
  aside nav.menu--tematicas h2,
  aside nav.menu--menu-informacion-sectorial h2 {
    margin: 0px 0px 16px 0px;
    border-bottom: 0px;
    padding-bottom: 0px;
    text-shadow: none;
    font-size: .9rem;
    font-weight: bold;
    color: #010917;
    text-transform: uppercase; }
  aside nav.menu--tematicas ul,
  aside nav.menu--menu-informacion-sectorial ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    margin: 0px !important; }
    aside nav.menu--tematicas ul li,
    aside nav.menu--menu-informacion-sectorial ul li {
      display: block;
      width: 100%; }
      aside nav.menu--tematicas ul li a,
      aside nav.menu--menu-informacion-sectorial ul li a {
        text-decoration: none;
        color: #4C6B80;
        margin-bottom: 8px;
        display: block;
        padding: 0px; }
      aside nav.menu--tematicas ul li a:hover,
      aside nav.menu--menu-informacion-sectorial ul li a:hover {
        color: #010917; }

.boton-azul {
  background: #007BC4;
  border-radius: 4px;
  text-decoration: none !important;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF !important;
  padding: 8px 18px; }
  .boton-azul:hover {
    text-decoration: none !important;
    color: #0061B2 !important;
    background: #fff;
    border: 1px solid #0061B2; }
  .boton-azul:focus {
    text-decoration: none !important;
    color: #0061B2 !important;
    background: #fff;
    border: 1px solid #0061B2; }

.block-pestanas-page-block.d-none,
.block-pestanas-page-block {
  background-color: #F5F9FF;
  padding: 0px 0px 0px 0px;
  margin: 30px 0px;
  display: block !important; }
  .block-pestanas-page-block.d-none .nav-tabs,
  .block-pestanas-page-block .nav-tabs {
    background-color: #fff;
    border: 0px;
    padding: 0px; }
    .block-pestanas-page-block.d-none .nav-tabs .nav-item,
    .block-pestanas-page-block .nav-tabs .nav-item {
      list-style: none;
      margin-bottom: 0px !important;
      position: relative;
      max-width: 33.33%; }
      .block-pestanas-page-block.d-none .nav-tabs .nav-item:before,
      .block-pestanas-page-block .nav-tabs .nav-item:before {
        content: ""; }
    .block-pestanas-page-block.d-none .nav-tabs .nav-link,
    .block-pestanas-page-block .nav-tabs .nav-link {
      border: 0px;
      font-weight: 700;
      color: #4C6B80;
      height: 100%; }
    .block-pestanas-page-block.d-none .nav-tabs .nav-link.active,
    .block-pestanas-page-block .nav-tabs .nav-link.active {
      background-color: #F5F9FF;
      color: #007bc4; }
  .block-pestanas-page-block.d-none .tab-content,
  .block-pestanas-page-block .tab-content {
    padding: 20px 15px 20px 15px; }

.node__content .body-container ul li,
.node__content .tab-content ul li,
.node__content .card-body ul li,
.node__content .field__item ul li {
  list-style: none;
  margin-bottom: 10px;
  position: relative; }
  .node__content .body-container ul li:before,
  .node__content .tab-content ul li:before,
  .node__content .card-body ul li:before,
  .node__content .field__item ul li:before {
    position: relative;
    top: 0px;
    left: -8px;
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-size: .8rem;
    font-weight: bold;
    color: #007bc4;
    text-decoration: none; }
  .node__content .body-container ul li ul,
  .node__content .tab-content ul li ul,
  .node__content .card-body ul li ul,
  .node__content .field__item ul li ul {
    margin: 15px 0px 0px 0px; }
  .node__content .body-container ul li a:hover,
  .node__content .tab-content ul li a:hover,
  .node__content .card-body ul li a:hover,
  .node__content .field__item ul li a:hover {
    text-decoration: underline; }

.node__content .nav-item {
  position: initial; }

.field--name-field-enlaces-da-publicacion a:before {
  position: relative;
  top: 0px;
  left: 0px;
  font-family: 'Font Awesome 5 Free';
  content: "\f061";
  font-size: .8rem;
  font-weight: bold;
  color: #007bc4;
  text-decoration: none;
  margin-right: 8px; }

nav .breadcrumb li {
  color: #fff; }
  nav .breadcrumb li a {
    color: #fff;
    font-weight: bold; }
    nav .breadcrumb li a:hover {
      color: #fff;
      font-weight: bold; }

nav .breadcrumb li.active {
  color: #FFFFFF;
  font-weight: bold; }

.node__content {
  font-size: 1rem;
  margin-top: 5px; }
  .node__content h3:not(:first-child) {
    margin-top: 32px; }
  .node__content .field--name-field-enlaces-da-publicacion {
    background-color: #F5F9FF;
    padding: 20px;
    margin-top: 20px; }
    .node__content .field--name-field-enlaces-da-publicacion .field__label {
      margin-top: 0; }

.path-frontpage #main {
  margin-top: 0px; }

#main {
  margin-top: 0px; }

.path-node .node__content {
  margin-bottom: 50px;
  top: 0px; }
  .path-node .node__content .field--type-datetime {
    font-size: 12px;
    font-weight: 700;
    color: #4C6B80;
    padding-bottom: 25px;
    padding-top: 10px; }

.field__label {
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 5px;
  color: #010917; }
  .field__label .gris {
    color: #4C6B80; }

.node--type-noticias .field__label .gris {
  color: #4C6B80; }

.node--type-noticias .field--name-body a {
  color: #007bc4;
  text-decoration: none;
  word-break: break-all; }

.node--type-noticias .field--name-field-imaxe-da-nova .slick-track {
  float: left; }
  .node--type-noticias .field--name-field-imaxe-da-nova .slick-track img {
    height: auto;
    width: 100%; }

.node--type-noticias .field.field--name-field-enlaces-da-publicacion .field--name-field-enlaces-da-publicacion .field__item a {
  position: relative;
  padding-left: 22px;
  margin-bottom: 6px;
  display: block; }

.node--type-noticias .field.field--name-field-enlaces-da-publicacion .field--name-field-enlaces-da-publicacion .field__item a:before {
  position: absolute;
  top: 0px;
  left: 0px;
  font-family: 'Font Awesome 5 Free';
  content: "\f0c1";
  font-size: 1rem;
  font-weight: bold;
  color: #007bc4;
  text-decoration: none; }

.path-node .node__content .slider-page h3,
.path-node .node__content .normativa-page h3,
.path-node .node__content .enlaces-relacionados-page h3,
.path-node .node__content .otros-page h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
  color: #4C6B80; }
  .path-node .node__content .slider-page h3 strong,
  .path-node .node__content .normativa-page h3 strong,
  .path-node .node__content .enlaces-relacionados-page h3 strong,
  .path-node .node__content .otros-page h3 strong {
    font-weight: 700;
    color: #010917; }

.path-node .node__content .slider-page {
  margin-bottom: 25px;
  margin-top: 40px; }
  .path-node .node__content .slider-page .slick-slide:hover {
    cursor: pointer; }
    .path-node .node__content .slider-page .slick-slide:hover a {
      font-size: 25px; }
  .path-node .node__content .slider-page .col-md-12 {
    padding: 0; }

.path-node .node__content .normativa-page {
  margin-bottom: 25px; }
  .path-node .node__content .normativa-page .field__item {
    margin-bottom: 8px; }
  .path-node .node__content .normativa-page .col-md-12 {
    padding: 0; }

.path-node .node__content .enlaces-relacionados-page {
  margin-bottom: 25px;
  background-color: #F5F9FF;
  padding: 20px 20px; }
  .path-node .node__content .enlaces-relacionados-page .field__item {
    margin-bottom: 8px; }
  .path-node .node__content .enlaces-relacionados-page a {
    position: relative;
    padding-left: 30px; }
    .path-node .node__content .enlaces-relacionados-page a:before {
      position: absolute;
      top: -6px;
      left: 2px;
      font-family: 'Font Awesome 5 Free';
      content: "\f0c1";
      font-size: 1rem;
      font-weight: bold;
      color: #007bc4;
      text-decoration: none; }

.path-node .node__content .otros-page {
  margin-bottom: 25px; }

.path-node .node__content .row-bloque-contacto {
  margin: 10px -15px 0px -15px; }
  .path-node .node__content .row-bloque-contacto .container-bloque-contacto {
    padding: 20px;
    background-color: #007bc4;
    display: flex;
    flex-wrap: wrap; }
    .path-node .node__content .row-bloque-contacto .container-bloque-contacto p {
      color: #fff;
      font-weight: 400; }
    .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row {
      margin-top: 15px;
      align-self: flex-end;
      width: 100%; }
      .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div {
        display: flex;
        align-items: center;
        width: auto;
        margin-bottom: 15px; }
        .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div i {
          display: inline-block;
          color: #fff;
          font-size: .9rem;
          margin-right: 8px; }
        .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div div, .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div a {
          color: #fff;
          font-weight: 400;
          line-height: 14px;
          font-size: .75rem;
          text-decoration: none; }
          .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div div:hover, .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div a:hover {
            text-decoration: none; }
        .path-node .node__content .row-bloque-contacto .container-bloque-contacto .row > div a:hover {
          text-decoration: underline; }

.page-node-26 .col {
  width: 73.666667%;
  flex: 0 0 auto; }

.page-node-57 .col,
.page-node-52 .col {
  width: 66.66666667%;
  flex: 0 0 auto; }

.file--application-pdf {
  background-image: none;
  position: relative;
  padding-left: 30px; }
  .file--application-pdf:before {
    position: absolute;
    top: 0px;
    left: 0px;
    font-family: 'Font Awesome 5 Free';
    content: "\f0c1";
    font-size: 1rem;
    font-weight: bold;
    color: #007bc4;
    text-decoration: none; }

.webform-submission-formulario-colabora-form {
  margin: 0px 0px 40px 0px; }
  .webform-submission-formulario-colabora-form .fieldset-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -15px; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper h3 {
      font-weight: 400;
      font-size: 18px;
      color: #4C6B80; }
      .webform-submission-formulario-colabora-form .fieldset-wrapper h3 strong {
        color: #010917; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper label {
      font-weight: 700;
      font-size: .8rem;
      color: #4C6B80 !important;
      margin-bottom: 2px !important; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper input::placeholder, .webform-submission-formulario-colabora-form .fieldset-wrapper textarea::placeholder {
      color: #A7ADB6; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper .js-form-type-webform-terms-of-service a {
      color: #007bc4; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper .form-control {
      border: 1px solid #C5CFD6 !important; }
    .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button {
      display: flex;
      flex-wrap: wrap; }
      .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .form-type-webform-terms-of-service {
        display: inline-block;
        width: 70%; }
        .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .form-type-webform-terms-of-service .form-check-label {
          font-weight: 400; }
      .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .captcha {
        width: 70%; }
      .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .webform-actions {
        display: inline-block;
        width: 30%;
        text-align: right; }
        .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .webform-actions button {
          padding: 8px 22px;
          background-color: #007bc4;
          border-color: #007bc4; }
          .webform-submission-formulario-colabora-form .fieldset-wrapper #edit-container-button .webform-actions button:hover {
            background: #fff;
            color: #007bc4;
            border: 1px solid #007bc4; }
  .webform-submission-formulario-colabora-form .btn-primary {
    color: #fff;
    background-color: #007bc4;
    border-color: #007bc4; }
    .webform-submission-formulario-colabora-form .btn-primary:hover {
      background-color: #FFF;
      color: #007bc4; }

.page-node-48 .post-header .container-fluid, .page-node-48 .post-header .container-sm, .page-node-48 .post-header .container-md, .page-node-48 .post-header .container-lg, .page-node-48 .post-header .container-xl {
  display: flex;
  flex-wrap: wrap; }
  .page-node-48 .post-header .container-fluid #block-arqbootstrap4-breadcrumbs, .page-node-48 .post-header .container-sm #block-arqbootstrap4-breadcrumbs, .page-node-48 .post-header .container-md #block-arqbootstrap4-breadcrumbs, .page-node-48 .post-header .container-lg #block-arqbootstrap4-breadcrumbs, .page-node-48 .post-header .container-xl #block-arqbootstrap4-breadcrumbs {
    width: 100%;
    order: 0; }
  .page-node-48 .post-header .container-fluid #block-views-block-slider-ultimas-publicaciones-block-1, .page-node-48 .post-header .container-sm #block-views-block-slider-ultimas-publicaciones-block-1, .page-node-48 .post-header .container-md #block-views-block-slider-ultimas-publicaciones-block-1, .page-node-48 .post-header .container-lg #block-views-block-slider-ultimas-publicaciones-block-1, .page-node-48 .post-header .container-xl #block-views-block-slider-ultimas-publicaciones-block-1 {
    width: 100%;
    order: 2; }
  .page-node-48 .post-header .container-fluid .page-title, .page-node-48 .post-header .container-sm .page-title, .page-node-48 .post-header .container-md .page-title, .page-node-48 .post-header .container-lg .page-title, .page-node-48 .post-header .container-xl .page-title {
    width: 100%;
    order: 1; }

.page-node-48 .container-sin-resultados .titulo {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: left; }

.view-slider-ultimas-publicaciones .slick-slider .slick-prev,
.view-slider-ultimas-publicaciones .slick-slider .slick-next {
  top: -20px !important; }

.view-slider-ultimas-publicaciones .slick-slider .slick-prev,
.view-slider-ultimas-publicaciones .slider-page .slick-prev {
  left: -20px; }

.view-slider-ultimas-publicaciones .slick-slider .slick-prev:before,
.view-slider-ultimas-publicaciones .slider-page .slick-prev:before {
  color: #fff; }

.view-slider-ultimas-publicaciones .slick-slider .slick-next,
.view-slider-ultimas-publicaciones .slider-page .slick-next {
  right: 10px; }

.view-slider-ultimas-publicaciones .slick-slider .slick-next:before,
.view-slider-ultimas-publicaciones .slider-page .slick-next:before {
  color: #fff; }

.view-slider-ultimas-publicaciones .slick-track {
  display: flex;
  flex-wrap: nowrap;
  height: 100%; }
  .view-slider-ultimas-publicaciones .slick-track .slick-slide {
    display: inline-block;
    float: none;
    border-bottom: 2px solid #fff;
    /*margin-right: 25px;*/
    /*Para que mostre parte do ultimo elemento*/
    margin: 0px 15px; }
    .view-slider-ultimas-publicaciones .slick-track .slick-slide a {
      text-decoration: none;
      transition: .3s ease; }
      .view-slider-ultimas-publicaciones .slick-track .slick-slide a:hover {
        text-decoration: none;
        opacity: 0.8; }
    .view-slider-ultimas-publicaciones .slick-track .slick-slide .views-field-title {
      font-size: 1.25rem;
      font-weight: 700;
      text-decoration: none;
      margin-bottom: 5px; }
    .view-slider-ultimas-publicaciones .slick-track .slick-slide .views-field-field-fecha- {
      font-size: 12px;
      color: #FFF;
      margin-bottom: 16px; }
    .view-slider-ultimas-publicaciones .slick-track .slick-slide .views-field-body {
      font-weight: 400; }

table {
  margin: 30px 0px 10px 0px;
  border: 1px solid #1C6EA4;
  background-color: #EEEEEE;
  width: 100%;
  text-align: left;
  border-collapse: collapse; }
  table td, table th {
    border: 1px solid #e6ebf2;
    padding: 3px 2px; }
  table tbody td {
    font-size: 16px;
    padding: 7px 10px; }
  table tbody tr {
    background-color: #F5F9FF; }
  table tr:nth-child(even) {
    background: #ffffff; }
  table thead {
    background: #007bc4;
    border: 1px solid #007bc4; }
  table thead th {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    border-left: 1px solid #e6ebf2;
    padding: 7px 10px; }
  table thead th:first-child {
    border-left: none; }

.pestanas-page .tab-content img {
  margin: 20px;
  margin-left: 30%;
  width: 40%;
  height: auto; }

.block-views-blocklistado-noticias-block-1 .view-listado-noticias {
  display: flex;
  flex-wrap: wrap;
  margin: 0px -15px; }
  .block-views-blocklistado-noticias-block-1 .view-listado-noticias nav {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px; }

.block-views-blocklistado-noticias-block-1 .view-header {
  width: 100%;
  padding: 0px 15px;
  margin-bottom: 16px; }
  .block-views-blocklistado-noticias-block-1 .view-header h2 {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 28px; }

.block-views-blocklistado-noticias-block-1 .view-filters {
  width: 25%; }
  .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form {
    background-color: #F5F9FF;
    padding: 40px; }
    .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .view-header {
      width: 100%;
      padding: 0px; }
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .view-header p {
        font-size: .9rem;
        font-weight: 900;
        color: #010917; }
    .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row {
      justify-content: flex-end; }
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-item-field-fecha__value:after,
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-item-field-fecha__value-1:after {
        content: url(../images/icons/calendar_icon.png);
        left: 18px;
        bottom: 2px;
        text-decoration: none;
        display: inline-block;
        position: absolute; }
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row fieldset {
        width: 100%;
        position: relative;
        margin-bottom: 8px !important; }
        .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row fieldset label {
          font-weight: 700;
          font-size: .7rem;
          color: #4C6B80;
          margin-bottom: .2rem; }
        .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row fieldset .form-control {
          padding: .375rem .75rem .375rem 2.4rem; }
        .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row fieldset .form-control {
          padding: .375rem .75rem .375rem 2.4rem; }
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .js-form-item-title .form-control {
        padding: .375rem .75rem .375rem 14px; }
      .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions {
        margin: 20px 0px 0px 0px;
        display: flex;
        flex-direction: row-reverse; }
        .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary {
          padding: 8px 16px;
          font-size: 1rem;
          font-weight: 700;
          background: #007BC4;
          border-radius: 5px;
          border: 1px solid #007BC4; }
          .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary:hover,
          .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary :focus {
            background: #fff;
            color: #007BC4;
            border: 1px solid #007BC4; }
        .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary[data-drupal-selector="edit-reset"] {
          padding: 8px 16px;
          font-size: 1rem;
          font-weight: 700;
          background: #4C6B80;
          border-radius: 5px;
          border: 1px solid #4C6B80;
          margin-right: 10px; }
          .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary[data-drupal-selector="edit-reset"]:hover,
          .block-views-blocklistado-noticias-block-1 .view-filters .views-exposed-form .form-row .form-actions .btn-primary[data-drupal-selector="edit-reset"] :focus {
            background: #fff;
            color: #007BC4;
            border: 1px solid #007BC4; }

.block-views-blocklistado-noticias-block-1 .view-content {
  width: 73%;
  padding: 0px 0px 0px 2%; }
  .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal {
    margin-bottom: 20px; }
    .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      text-decoration: none; }
      .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-field-fecha- {
        display: inline-block;
        min-width: 165px;
        font-size: 12px;
        color: #A6B5C0; }
      .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-container {
        display: inline-block;
        flex: 1;
        border-bottom: 1px solid #E6EBF2; }
        .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-container .views-field-title {
          display: block;
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 5px;
          color: #010917; }
        .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-container .views-field-body {
          display: block;
          color: #4C6B80;
          font-weight: 400;
          margin-bottom: 20px; }
    .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a:hover {
      opacity: 0.8; }

.block-views-blocklistado-noticias-block-1 .view-empty {
  width: 73%;
  padding: 0px 0px 0px 2%; }

.page-node-626 .post-header {
  margin-bottom: 0; }

.page-node-626 .ui-dialog {
  position: absolute !important;
  width: 80% !important;
  top: 100px !important;
  left: 10% !important; }

.webform-submission-formulario-de-contacto-form {
  margin-top: -35px; }
  .webform-submission-formulario-de-contacto-form .webform-flexbox {
    flex-wrap: wrap; }
    .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child {
      flex-basis: 100%; }
      @media (min-width: 992px) {
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child {
          flex-basis: 60%; } }
      @media (min-width: 1200px) {
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child {
          flex-basis: 70%; } }
      .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario {
        flex-wrap: wrap; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .webform-flex:nth-child(1), .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .webform-flex:nth-child(2) {
          flex-basis: 50%; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .webform-flex:nth-child(3), .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .webform-flex:nth-child(4), .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .webform-flex:nth-child(5) {
          flex-basis: 100%; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario label {
          color: #4C6B80;
          font-size: 10px;
          line-height: 12px;
          font-weight: bold; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario input, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario textarea {
          -webkit-text-fill-color: #A7ADB6;
          font-size: 16px;
          border-radius: 3px;
          border: 1px solid #C5CFD6; }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario input:hover, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario input:focus, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario textarea:hover, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario textarea:focus {
            border: 2px solid #007bc4;
            box-shadow: none; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario textarea {
          border-radius: 3px; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .form-type-webform-terms-of-service {
          font-size: 12px; }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .form-type-webform-terms-of-service input {
            font-size: 12px; }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:first-child .formulario .form-type-webform-terms-of-service a {
            text-decoration: none;
            color: #007BC4; }
    .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child {
      flex-basis: 100%; }
      @media (min-width: 992px) {
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child {
          flex-basis: 40%; } }
      @media (min-width: 1200px) {
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child {
          flex-basis: 30%; } }
      .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion {
        background-color: #fafaf9;
        padding: 24px;
        display: flex;
        flex-wrap: wrap; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion > div {
          flex-basis: 100%; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .titulo {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 13px; }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .titulo .sub {
            color: #4C6B80;
            font-weight: 400; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .tel1, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .mail {
          flex-basis: 100%;
          margin-top: 24px; }
          @media (min-width: 400px) {
            .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .tel1, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .mail {
              flex-basis: 50%; } }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .tel1 i, .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .mail i {
            margin-right: 10px; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .mail a {
          color: #212529;
          text-decoration: none; }
        .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .redes {
          display: flex;
          margin-top: 24px; }
          .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .redes > div {
            margin-right: 20px; }
            .webform-submission-formulario-de-contacto-form .webform-flexbox > .webform-flex:last-child .informacion .redes > div i {
              color: #212529; }
  .webform-submission-formulario-de-contacto-form #edit-actions {
    text-align: right;
    width: 100%;
    padding-right: 15px; }
    @media (min-width: 992px) {
      .webform-submission-formulario-de-contacto-form #edit-actions {
        width: 60%; } }
    @media (min-width: 1200px) {
      .webform-submission-formulario-de-contacto-form #edit-actions {
        width: 70%; } }
    .webform-submission-formulario-de-contacto-form #edit-actions button {
      background-color: #007bc4;
      border-color: #007bc4;
      border-radius: 5px;
      padding: 10px 20px;
      line-height: 16px; }
      .webform-submission-formulario-de-contacto-form #edit-actions button:hover {
        color: #fff;
        background-color: #0b5ed7;
        border-color: #0a58ca; }

.page-node-674 .sidebar_first {
  width: 100% !important; }
  @media (min-width: 768px) {
    .page-node-674 .sidebar_first {
      width: 40% !important; } }
  @media (min-width: 992px) {
    .page-node-674 .sidebar_first {
      width: 30% !important; } }
  @media (min-width: 1400px) {
    .page-node-674 .sidebar_first {
      width: 25% !important; } }

.page-node-674 #block-arqbootstrap4-content {
  display: none; }

.view-servizos .view-header {
  margin-bottom: 36px; }
  .view-servizos .view-header .titulo {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px; }

.view-servizos .views-exposed-form {
  display: none; }

.view-servizos .views-view-grid .row .col-12 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EBF2; }

.view-servizos .views-view-grid .row .views-field-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px; }

.view-servizos .views-view-grid .row .views-field-field-corpo {
  color: #4C6B80; }

.view-servizos .views-view-grid .row .views-field-edit-node {
  margin-bottom: 20px; }

.view-servizos .views-view-grid .row .etiquetas {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }
  .view-servizos .views-view-grid .row .etiquetas > div {
    font-size: 12px;
    line-height: 12px;
    padding: 6px 8px;
    height: 22px;
    margin-right: 20px; }
  .view-servizos .views-view-grid .row .etiquetas .tipo {
    background-color: #007bc4;
    color: #FFF; }
  .view-servizos .views-view-grid .row .etiquetas .formato {
    background-color: #4C6B80;
    color: #FFF; }
  .view-servizos .views-view-grid .row .etiquetas .organismo {
    color: #4C6B80;
    border-left: 2px solid #4C6B80;
    font-weight: bold;
    margin-right: 0; }

.view-servizos .views-view-grid .row .enlaces {
  display: flex;
  flex-wrap: wrap; }
  .view-servizos .views-view-grid .row .enlaces > div {
    margin-right: 50px; }
  .view-servizos .views-view-grid .row .enlaces a {
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 0px;
    text-decoration: none;
    color: #007bc4;
    padding-left: 30px;
    display: block;
    height: 20px; }
  .view-servizos .views-view-grid .row .enlaces .enlace a {
    background-image: url("../images/icons/cadena.svg"); }
  .view-servizos .views-view-grid .row .enlaces .capacidad a {
    background-image: url("../images/icons/laptop.svg"); }
  .view-servizos .views-view-grid .row .enlaces .relacionada a {
    background-image: url("../images/icons/info.svg"); }

.view-servizos .views-view-grid .row .views-field-nothing-2, .view-servizos .views-view-grid .row .views-field-field-tipoloxia {
  margin-top: 35px;
  font-size: 12px;
  line-height: 12px;
  color: #4C6B80; }

.view-servizos .views-view-grid .row .views-field-field-tipoloxia {
  margin-top: 10px; }

.view-servizos .pagination {
  justify-content: flex-end;
  /*
    .page-link{
      border-radius: 5px !important;
    }
    a, .active .page-link{
      font-size: 14px;
      line-height: 22px;
    }
    a{
      color:#505B6D !important;
    }
    */ }

.block-views-exposed-filter-blockservizos-page-1, .block-views-exposed-filter-blockiniciativas-page-1, .block-views-exposed-filter-blocknodos-xunta-page-1 {
  background-color: #F5F9FF;
  padding: 40px; }
  .block-views-exposed-filter-blockservizos-page-1 h2, .block-views-exposed-filter-blockiniciativas-page-1 h2, .block-views-exposed-filter-blocknodos-xunta-page-1 h2 {
    font-size: .9rem;
    font-weight: 900;
    color: #010917;
    margin-bottom: 16px; }
  .block-views-exposed-filter-blockservizos-page-1 fieldset, .block-views-exposed-filter-blockiniciativas-page-1 fieldset, .block-views-exposed-filter-blocknodos-xunta-page-1 fieldset {
    width: 100% !important; }
    .block-views-exposed-filter-blockservizos-page-1 fieldset select, .block-views-exposed-filter-blockiniciativas-page-1 fieldset select, .block-views-exposed-filter-blocknodos-xunta-page-1 fieldset select {
      color: #4C6B80; }
  .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"], .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"], .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse; }
    .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"] button, .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"] button, .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] button {
      margin-left: 10px; }
      .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"] button:nth-child(2), .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"] button:nth-child(2), .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] button:nth-child(2) {
        background: #4C6B80;
        border: 1px solid #4C6B80; }
    .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"] .btn-primary, .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"] .btn-primary, .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] .btn-primary {
      padding: 8px 16px;
      font-size: 1rem;
      font-weight: 700;
      background: #007bc4;
      border-radius: 5px;
      border: 1px solid #007bc4; }
      .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"] .btn-primary:hover,
      .block-views-exposed-filter-blockservizos-page-1 div[data-drupal-selector="edit-actions"] .btn-primary :focus, .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"] .btn-primary:hover,
      .block-views-exposed-filter-blockiniciativas-page-1 div[data-drupal-selector="edit-actions"] .btn-primary :focus, .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] .btn-primary:hover,
      .block-views-exposed-filter-blocknodos-xunta-page-1 div[data-drupal-selector="edit-actions"] .btn-primary :focus {
        background: #fff;
        color: #007bc4;
        border: 1px solid #007bc4; }
  .block-views-exposed-filter-blockservizos-page-1 label, .block-views-exposed-filter-blockiniciativas-page-1 label, .block-views-exposed-filter-blocknodos-xunta-page-1 label {
    font-weight: 700;
    font-size: .7rem;
    color: #4C6B80;
    margin-bottom: .2rem; }

.block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value, .block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value-1, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value-1 {
  position: relative; }
  .block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value::after, .block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value-1::after, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value::after, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value-1::after {
    content: url(../images/icons/calendar_icon.png);
    left: 18px;
    bottom: 2px;
    text-decoration: none;
    display: inline-block;
    position: absolute; }
  .block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value input, .block-views-exposed-filter-blockiniciativas-page-1 .form-item-field-data-iniciativa-value-1 input, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value input, .block-views-exposed-filter-blocknodos-xunta-page-1 .form-item-field-data-iniciativa-value-1 input {
    padding: 0.375rem 0.75rem 0.375rem 2.4rem; }

.page-node-51 #sidebar_second {
  display: none; }

.view-iniciativas .view-header, .view-nodos-xunta .view-header {
  margin-bottom: 50px; }
  .view-iniciativas .view-header .titulo, .view-nodos-xunta .view-header .titulo {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px; }

.view-iniciativas .views-exposed-form, .view-nodos-xunta .views-exposed-form {
  display: none; }

.view-iniciativas .views-view-grid .row .col-12, .view-nodos-xunta .views-view-grid .row .col-12 {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E6EBF2; }

.view-iniciativas .views-view-grid .row .views-field-title, .view-nodos-xunta .views-view-grid .row .views-field-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px; }

.view-iniciativas .views-view-grid .row .views-field-body, .view-nodos-xunta .views-view-grid .row .views-field-body {
  color: #4C6B80; }

.view-iniciativas .views-view-grid .row .views-field-edit-node, .view-nodos-xunta .views-view-grid .row .views-field-edit-node {
  margin-bottom: 20px; }

.view-iniciativas .views-view-grid .row .views-field-field-ligazon-principal, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazon-principal {
  text-align: right;
  margin-bottom: 10px; }
  .view-iniciativas .views-view-grid .row .views-field-field-ligazon-principal a, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazon-principal a {
    background-image: url("../images/icons/arrow-right.svg");
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: 85% 43%;
    background-color: #007bc4;
    padding: 8px 40px 8px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #FFF;
    line-height: 14px; }
    .view-iniciativas .views-view-grid .row .views-field-field-ligazon-principal a:hover, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazon-principal a:hover {
      background-image: url("../images/icons/arrow-right-xunta.svg");
      background-color: #fff;
      color: #007bc4;
      border: 1px solid #007bc4; }

.view-iniciativas .views-view-grid .row .views-field-field-ligazons-iniciativas, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazons-iniciativas {
  margin-top: 5px; }
  .view-iniciativas .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group {
    margin: 0; }
    .view-iniciativas .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group .list-group-item, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group .list-group-item {
      border: none; }
      .view-iniciativas .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group .list-group-item a, .view-nodos-xunta .views-view-grid .row .views-field-field-ligazons-iniciativas .list-group .list-group-item a {
        background-image: url("../images/icons/cadena.svg");
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: 0px;
        text-decoration: none;
        color: #007bc4;
        padding-left: 30px;
        display: block;
        height: 20px; }

.view-iniciativas .pagination, .view-nodos-xunta .pagination {
  justify-content: flex-end; }
  .view-iniciativas .pagination .page-link, .view-nodos-xunta .pagination .page-link {
    border-radius: 5px !important; }
  .view-iniciativas .pagination a, .view-iniciativas .pagination .active .page-link, .view-nodos-xunta .pagination a, .view-nodos-xunta .pagination .active .page-link {
    font-size: 14px;
    line-height: 22px; }
  .view-iniciativas .pagination a, .view-nodos-xunta .pagination a {
    color: #505B6D !important; }

.page-node-674 ul {
  padding-left: 0; }

.ui-dialog .ui-dialog-titlebar {
  background-color: white;
  border: none; }
  .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-close {
    background-color: white;
    border: none; }

.ui-dialog .ui-dialog-content {
  padding: 0.5em 3em 3em 3em; }

.ui-dialog .webform-terms-of-service-details--content {
  font-family: "Xunta Sans";
  color: #010917; }

.view-publicaciones .views-view-grid .row > .col-12 {
  margin-bottom: 40px; }

.view-publicaciones .views-field-nothing .field-content {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 400px) {
    .view-publicaciones .views-field-nothing .field-content {
      flex-wrap: nowrap; } }
  .view-publicaciones .views-field-nothing .field-content .imaxe {
    flex-basis: 100%; }
    @media (min-width: 400px) {
      .view-publicaciones .views-field-nothing .field-content .imaxe {
        flex-basis: 40%; } }
    @media (min-width: 576px) {
      .view-publicaciones .views-field-nothing .field-content .imaxe {
        flex-basis: auto; } }
    .view-publicaciones .views-field-nothing .field-content .imaxe img {
      width: 100%;
      height: auto; }
      @media (min-width: 576px) {
        .view-publicaciones .views-field-nothing .field-content .imaxe img {
          width: 200px; } }
  .view-publicaciones .views-field-nothing .field-content .contido {
    flex-basis: 100%;
    margin-top: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #E6EBF2; }
    @media (min-width: 400px) {
      .view-publicaciones .views-field-nothing .field-content .contido {
        flex-basis: 60%;
        margin-top: 0px;
        margin-left: 20px; } }
    @media (min-width: 576px) {
      .view-publicaciones .views-field-nothing .field-content .contido {
        flex-basis: auto;
        margin-top: 0px;
        margin-left: 20px; } }
    .view-publicaciones .views-field-nothing .field-content .contido .titulo {
      color: #010917;
      font-size: 20px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 15px; }
    .view-publicaciones .views-field-nothing .field-content .contido .logo-descarga, .view-publicaciones .views-field-nothing .field-content .contido .texto-descarga {
      display: none; }
    .view-publicaciones .views-field-nothing .field-content .contido a.descarga {
      margin-top: 25px;
      display: flex;
      font-size: 14px;
      line-height: 24px;
      text-decoration: none;
      color: #007bc4; }
      .view-publicaciones .views-field-nothing .field-content .contido a.descarga .logo-descarga {
        display: block;
        background-image: url(../images/icons/descarga.svg);
        background-position: 0px 3px;
        background-repeat: no-repeat;
        width: 24px; }
      .view-publicaciones .views-field-nothing .field-content .contido a.descarga .texto-descarga {
        display: block; }

/*Pestanas en pax basica
.block-pestanas-page-block {
  padding-top: 30px;
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #002b4a!important;
    background-color: transparent!important;
    border-right: 2px solid #002b4a;
    border-radius: 0px;
  }
}



@media (max-width: 575px) {
  .block-pestanas-page-block {
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
      border-bottom: 2px solid #002b4a;
      border-right: none!important;
      margin-bottom:10px;
    }
    .nav-pills .nav-link.active, .nav-pills .>.nav-link {
      border-bottom: 2px solid #002b4a;
      border-right: none!important;
      margin-bottom:10px;
    }
  }
}
*/
.view-id-slider_de_enlaces_por_nodo .slick-slider .slick-slide .views-row, .view-id-slider_de_enlaces_por_nodo .slider-page .slick-slide .views-row {
  width: 100% !important; }

.view-id-slider_de_enlaces_por_nodo .slick-list {
  padding-left: 0; }

.view-id-slider_de_enlaces_por_nodo .view-content {
  margin: 0; }

.page-node-52 .row-bloque-contacto {
  display: flex; }

/* xs */
@media (max-width: 767.98px) {
  .page-node-52 .row-bloque-contacto {
    display: flex;
    flex-wrap: wrap; }
    .page-node-52 .row-bloque-contacto .col-md-6:last-child {
      margin-top: 10px; }
  #main {
    margin-top: 30px; }
  nav .breadcrumb li {
    margin-bottom: 5px;
    line-height: 16px; }
  .block-views-blocklistado-noticias-block-1 .view-filters {
    width: 100%;
    padding: 0px 15px; }
  .block-views-blocklistado-noticias-block-1 .view-content, .block-views-blocklistado-noticias-block-1 .view-empty {
    width: 100%;
    padding: 0px 15px;
    margin-top: 50px; }
  .block-views-blocklistado-noticias-block-1 .view-listado-noticias nav {
    justify-content: center;
    width: auto;
    margin: 30px 15px 0px 15px; }
    .block-views-blocklistado-noticias-block-1 .view-listado-noticias nav ul {
      flex-wrap: wrap;
      justify-content: center; }
  .page-node-52 .col,
  .page-node-57 .col,
  .page-node-26 .col {
    width: 100%; } }

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  #main {
    margin-top: 30px; }
  nav .breadcrumb li {
    margin-bottom: 5px;
    line-height: 16px; }
  .block-views-blocklistado-noticias-block-1 .view-filters {
    width: 40%;
    padding: 0px 15px; }
  .block-views-blocklistado-noticias-block-1 .view-content {
    width: 60%;
    padding: 0px 15px; }
  .page-node-50 .sidebar_first,
  .page-node-51 .sidebar_first {
    width: 40%; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .page-node-50 .sidebar_first,
  .page-node-51 .sidebar_first {
    width: 40%; } }

.node--type-page .link-interno {
  clear: both;
  display: inline-block;
  margin: 20px 40px;
  text-decoration: none;
  border: 1px solid #007bc4;
  padding: 4px 10px 1px 10px;
  width: 24%;
  text-align: center; }

.footer .col-xs-12.col-md-2:last-child {
  background-image: url("../images/xacobeo21-22-negativo.png");
  background-repeat: no-repeat;
  background-position: 0 0px !important;
  float: right;
  background-size: contain;
  height: 30px;
  width: 190px;
  background-size: 160px;
  background-repeat: no-repeat; }

.path-frontpage #main {
  background: #007bc4;
  color: #fff;
  height: 400px;
  margin-bottom: 100px;
  /* Slider Home */ }
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-prev,
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-next {
    top: -5px !important; }
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-prev,
  .path-frontpage #main .block-slider-portada-block .slider-page .slick-prev {
    left: -65px; }
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-prev:before,
  .path-frontpage #main .block-slider-portada-block .slider-page .slick-prev:before {
    color: #fff; }
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-next,
  .path-frontpage #main .block-slider-portada-block .slider-page .slick-next {
    right: 40px; }
  .path-frontpage #main .block-slider-portada-block .slick-slider .slick-next:before,
  .path-frontpage #main .block-slider-portada-block .slider-page .slick-next:before {
    color: #fff; }
  .path-frontpage #main .block-slider-portada-block .front-title h2 {
    font-size: 2.45rem;
    font-weight: 600; }
  .path-frontpage #main .block-slider-portada-block .front-subtitle {
    font-size: 1.15rem;
    color: #fff;
    font-weight: 400; }
    .path-frontpage #main .block-slider-portada-block .front-subtitle strong {
      font-weight: 600;
      color: #fff; }
    .path-frontpage #main .block-slider-portada-block .front-subtitle ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0px; }
      .path-frontpage #main .block-slider-portada-block .front-subtitle ul li {
        display: inline-block;
        width: 50%;
        margin: 0px 0px 10px 0px;
        padding: 0px 0px 0px 20px;
        position: relative; }
        .path-frontpage #main .block-slider-portada-block .front-subtitle ul li:before {
          content: '';
          position: absolute;
          left: 0px;
          top: 50%;
          transform: translateY(-50%);
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid #007BC4;
          background: #fff; }
  .path-frontpage #main a {
    color: #fff; }
  .path-frontpage #main #tabsContent .front-ligazon a {
    background-color: #fff;
    color: #007bc4;
    padding: 8px 18px;
    text-decoration: none;
    border-radius: 4px;
    font-size: 16px; }
  .path-frontpage #main #tabsContent .front-ligazon a:hover {
    background-color: #007bc4;
    color: #FFF;
    border: 1px solid #FFF; }
  .path-frontpage #main .imaxeportada {
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 455px;
    position: relative; }
    .path-frontpage #main .imaxeportada .ligazons-portada {
      position: absolute;
      bottom: 52px;
      right: -9%;
      width: 110%; }
      .path-frontpage #main .imaxeportada .ligazons-portada .nav-tabs {
        border-bottom: none;
        height: 200px; }
      .path-frontpage #main .imaxeportada .ligazons-portada .nav-link.active {
        background-color: transparent;
        border: none; }
      .path-frontpage #main .imaxeportada .ligazons-portada .nav-link {
        position: relative;
        overflow: visible;
        transition: none;
        padding: 1rem .5rem 2rem .5rem; }
      .path-frontpage #main .imaxeportada .ligazons-portada .nav-link:hover, .path-frontpage #main .imaxeportada .ligazons-portada .nav-item:focus, .path-frontpage #main .imaxeportada .ligazons-portada .nav-link:focus {
        border: none;
        outline: none; }
        .path-frontpage #main .imaxeportada .ligazons-portada .nav-link:hover img, .path-frontpage #main .imaxeportada .ligazons-portada .nav-item:focus img, .path-frontpage #main .imaxeportada .ligazons-portada .nav-link:focus img {
          transform: scale(1.05);
          opacity: 1; }
      .path-frontpage #main .imaxeportada .ligazons-portada img {
        width: 100%;
        opacity: .8;
        object-fit: cover;
        object-position: center;
        height: 120px;
        border-radius: 10px;
        transition: .3s ease; }
      .path-frontpage #main .imaxeportada .ligazons-portada p {
        position: absolute;
        top: 135px;
        left: 10px;
        margin-top: 10px;
        margin-bottom: 0rem; }

.path-frontpage .view-portada-destacados .view-header .destacados-portada {
  font-size: 1.9rem;
  color: #4C6B80;
  width: 100%;
  display: block;
  margin-bottom: 30px;
  line-height: 30px; }
  .path-frontpage .view-portada-destacados .view-header .destacados-portada strong {
    color: #010917; }

.path-frontpage .view-portada-destacados .fila {
  height: 240px; }
  .path-frontpage .view-portada-destacados .fila .views-field-field-imaxe-destacados img {
    width: 100%;
    object-fit: cover;
    height: 220px; }
  .path-frontpage .view-portada-destacados .fila .contenedor-destacados {
    height: 220px; }
  .path-frontpage .view-portada-destacados .fila .contenedor-destacados:hover .views-field-title,
  .path-frontpage .view-portada-destacados .fila .contenedor-destacados:focus .views-field-title,
  .path-frontpage .view-portada-destacados .fila .contenedor-destacados:hover img,
  .path-frontpage .view-portada-destacados .fila .contenedor-destacados:focus img {
    transform: scale(1.01);
    opacity: 1; }
  .path-frontpage .view-portada-destacados .fila .views-field-title {
    position: relative;
    margin-bottom: -70px;
    display: block;
    height: 100px;
    bottom: 100px;
    background: linear-gradient(to bottom, transparent, #010917); }
    .path-frontpage .view-portada-destacados .fila .views-field-title a {
      position: absolute;
      bottom: 20px;
      top: inherit;
      color: #fff;
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: bold;
      left: 30px;
      right: 30px; }

.path-frontpage .view-portada-destacados .more-link {
  float: right; }
  .path-frontpage .view-portada-destacados .more-link a {
    color: #010917;
    font-size: 14px;
    font-weight: bold;
    text-decoration: underline; }
  .path-frontpage .view-portada-destacados .more-link a:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-size: .8rem;
    font-weight: bold;
    color: #303333;
    margin-left: 10px;
    text-decoration: none;
    display: inline-block; }

/* xs */
@media (max-width: 767.98px) {
  .path-frontpage #main #tabsContent .front-ligazon a {
    font-size: .9rem; }
  .path-frontpage #main .block-slider-portada-block .col-5 {
    width: 100%;
    height: 125px; }
    .path-frontpage #main .block-slider-portada-block .col-5 .front-title h2 {
      font-size: 1.8rem; }
  .path-frontpage #main .block-slider-portada-block .col-7 {
    width: 100%; }
    .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada {
      background-position: center;
      height: 350px;
      position: absolute;
      margin-top: 0px;
      left: 0px;
      bottom: -340px; }
      .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada {
        position: absolute;
        bottom: 70px;
        right: 0px;
        width: 100%; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada .nav-tabs {
          height: 180px; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada p {
          top: 115px; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada img {
          height: 100px; }
  .path-frontpage .main-content {
    margin-bottom: 60px; }
    .path-frontpage .main-content .view-portada-destacados .view-header .destacados-portada {
      font-size: 1.7rem; }
    .path-frontpage .main-content .view-portada-destacados .fila .views-field-title a {
      font-size: 1.1rem; } }

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  .path-frontpage #main .block-slider-portada-block .col-5 {
    width: 100%; }
    .path-frontpage #main .block-slider-portada-block .col-5 .front-title h2 {
      font-size: 1.8rem; }
  .path-frontpage #main .block-slider-portada-block .col-7 {
    width: 100%; }
    .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada {
      background-position: center;
      height: 350px; }
      .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada {
        position: absolute;
        bottom: 80px;
        right: 0px;
        width: 100%; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada .nav-tabs {
          height: 180px; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada img {
          height: 100px; }
        .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada p {
          top: 115px; }
  .path-frontpage .main-content {
    margin-bottom: 60px; }
    .path-frontpage .main-content .view-portada-destacados .view-header .destacados-portada {
      font-size: 1.7rem; }
    .path-frontpage .main-content .view-portada-destacados .fila .views-field-title a {
      font-size: 1rem; } }

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .path-frontpage #main .block-slider-portada-block .col-5 .front-title h2 {
    font-size: 1.9rem; }
  .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada {
    background-position: center;
    height: 565px; }
    .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada {
      position: absolute;
      bottom: 160px;
      right: 0px;
      width: 100%; }
  .path-frontpage .main-content {
    margin-bottom: 60px; }
    .path-frontpage .main-content .view-portada-destacados .view-header .destacados-portada {
      font-size: 1.8rem; }
    .path-frontpage .main-content .view-portada-destacados .fila .views-field-title a {
      font-size: 1.1rem; } }

/* XL */
@media (min-width: 1200px) and (max-width: 1400px) {
  .path-frontpage #main {
    /* Slider Home */ }
    .path-frontpage #main .block-slider-portada-block .front-title h2 {
      font-size: 2.45rem; }
    .path-frontpage #main .block-slider-portada-block .front-subtitle {
      font-size: 1rem; }
    .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada {
      background-position: center;
      height: 500px; }
      .path-frontpage #main .block-slider-portada-block .col-7 .imaxeportada .ligazons-portada {
        position: absolute;
        bottom: 100px;
        right: 0px;
        width: 100%; } }

@media (max-width: 1200px) {
  .block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-field-fecha- {
    width: 100%; } }

nav .breadcrumb li a {
  font-weight: bold; }

header .top-navbar-logos {
  height: 112px;
  padding: 0px; }

.footer .col-xs-12.col-md-2:last-child {
  background-image: url(/sites/default/files/Logo_xacobeo_2021.svg) !important; }

footer .footer {
  padding: 24px 0; }

footer .footer .logo-xunta a {
  width: 127px;
  height: 36px;
  background-size: 127px; }

footer .footer .links-xunta ul li {
  margin-right: 3px !important;
  font-size: 10px !important; }

footer .footer .col-xs-12 {
  height: 36px; }

.footer .col-xs-12.col-md-2:last-child {
  height: 36px !important;
  background-size: 153px;
  width: 16.6%; }

.footer .col-xs-12.col-md-2:last-child a {
  height: 36px !important;
  width: 190px !important;
  background-size: 190px !important; }

@media (max-width: 991px) {
  header .top-navbar-logos {
    height: 72px !important;
    padding: 0px; }
  header .top-navbar-logos .logo img {
    height: 36px !important; }
  footer .footer {
    height: 190px;
    padding: 14px 0; }
  footer .footer .links-xunta ul li a {
    font-size: 12px !important; }
  footer .footer .col-xs-12 {
    height: 28px; }
  footer .footer .logo-xunta a {
    width: 100px;
    height: 28px;
    background-size: 100px; }
  footer .div-logo-galicia {
    display: block;
    position: absolute;
    right: 15px;
    top: 0px px;
    width: 150px !important;
    height: 28px !important;
    background: url(/sites/default/files/Logo_xacobeo_2021.svg) no-repeat !important;
    background-size: 140px !important; }
  .footer .col-xs-12.col-md-2:last-child {
    display: none !important; }
  footer .footer .links-xunta ul li span {
    display: none; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer .col-md-2 {
    width: 100% !important; } }

footer .footer .links-xunta p {
  font-size: 10px; }

@media (max-width: 991px) {
  footer .footer .links-xunta {
    margin-top: 14px; }
  footer .footer .links-xunta p {
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px; }
  footer .footer .links-xunta ul {
    display: block;
    text-align: left;
    list-style-type: none !important;
    padding-left: 0;
    margin: 0; }
  footer .footer .links-xunta ul li {
    display: block;
    margin-top: 6px;
    float: none;
    text-align: left;
    font-size: 12px; } }

.block-views-blocklistado-noticias-block-1 .view-content .bloque-noticia-horizontal a .views-field-field-fecha- {
  color: #4c6b84 !important; }

footer .footer .links-xunta ul li a {
  font-size: 10px !important; }

@media (max-width: 768px) {
  header#header {
    position: fixed;
    width: 100%;
    top: auto;
    left: 0px;
    z-index: 100000;
    background: #007bc4;
    height: 72px; }
  header#header .top-navbar-logos {
    margin-bottom: -1px !important; }
  main.main-content {
    margin-top: 72px !important; }
  .cabecera-portada {
    padding-top: 20px; }
  footer .footer .links-xunta ul li a {
    font-size: 12px !important; } }

/* GENERAL STYLES
-------------------------------------------------*/
body {
  background: #fff;
  font-family: "Xunta Sans" !important;
  font-style: normal; }

/* EN PÁXINAS QUE NON TEÑEN MOITO CONTIDO O PÉ SUBE E DEIXAR UN OCO DESPOIS QUE NON DEBE SER. 
AXUSTAMOS O PÉ O FONDO DA PÁXINA NO CASO QUE O CONTIDO SEXA POUCO.*/
.dialog-off-canvas-main-canvas {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

main.main-content {
  flex-grow: 1; }

/* COLORS
--------------------------------------------------*/
.node__content a {
  color: #007bc4;
  text-decoration: none; }

.node__content a:hover {
  color: #0061B2;
  text-decoration: underline; }

.bg-inverse {
  background: #ff4e2e !important; }

.site-footer {
  background: #0079C0 !important; }

.menu--main li a {
  color: #ff4e2e; }

.menu--account li a {
  color: #ff4e2e; }

.site-name-slogan a {
  text-decoration: none; }

.card-group img {
  margin: 0;
  width: 100%;
  height: auto; }

.card-group .field--type-image {
  margin: 0; }

.slick {
  width: 100%; }

.slick > div {
  margin: 0 15px; }

.node__content h2 {
  font-size: 18px;
  margin-bottom: 15px;
  display: block;
  font-weight: bold; }

/*ANCHO*/

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 1600px;
  padding: 0px 5% !important; }

/* xs */
@media (max-width: 767.98px) {
  
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
    padding: 0px 15px !important; } }

@media (min-width: 1200px) {
  
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 100%;
    margin: 0px; } }

/*Paginador*/
.page-link {
  color: #007bc4 !important;
  border: none !important; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #007bc4 !important;
  border: none !important; }

/*ACORDEON*/
#accordion .card {
  border: none; }

#accordion .card-header {
  background-color: #007bc4;
  cursor: pointer;
  border: none;
  margin-bottom: 10px; }
  #accordion .card-header a {
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    font-weight: bold; }

#accordion .card-header.collapsed {
  background-color: #E5F2F9; }
  #accordion .card-header.collapsed a {
    color: #007bc4; }

#accordion .card-header:active,
#accordion .card-header:hover {
  background-color: #007bc4; }
  #accordion .card-header:active:after,
  #accordion .card-header:hover:after {
    color: #fff !important;
    border: 1px solid  #fff !important; }
  #accordion .card-header:active a,
  #accordion .card-header:hover a {
    text-decoration: none;
    color: #fff; }

#accordion .card-header:after {
  font-family: 'Font Awesome 5 Free';
  content: "\f068";
  float: right;
  font-size: .8rem;
  font-weight: bold;
  color: #fff;
  margin-left: 10px;
  border: 1px solid  #fff;
  padding: 0px 3px; }

#accordion .card-header.collapsed:after {
  content: "\f067";
  font-size: .8rem;
  font-weight: bold;
  color: #007bc4;
  margin-left: 10px;
  border: 1px solid #007bc4; }

#accordion .card-body {
  padding: 10px; }
  #accordion .card-body .field__item a:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-size: .8rem;
    font-weight: bold;
    color: #007bc4;
    margin-left: 10px;
    margin-right: 7px; }
  #accordion .card-body .field__item a {
    text-decoration: none;
    margin-bottom: 7px;
    display: block;
    color: #007bc4; }
  #accordion .card-body .field__item a:hover {
    color: #0061B2;
    text-decoration: underline; }

/*TITULO*/
.page-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px; }

/*SUBTITULO*/
.page-subtitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px; }
  .page-subtitle ul {
    list-style-type: circle; }
    .page-subtitle ul li {
      margin-bottom: 10px; }
    .page-subtitle ul li::marker {
      font-size: 150%;
      color: #007bc4; }

/*BLOQUE PUBLICACIONES DEL LATERAL*/
.path-node .sidebar_second .view-noticias {
  background-color: transparent;
  padding-top: 0px; }

.sidebar_second .view-noticias {
  background-color: #F5F9FF;
  padding: 40px;
  padding-top: 28px; }
  .sidebar_second .view-noticias .view-header .actualidad-portada {
    display: block;
    margin-bottom: 30px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left; }
  .sidebar_second .view-noticias .views-row {
    margin-bottom: 20px; }
    .sidebar_second .view-noticias .views-row .views-field-title a {
      color: #010917;
      text-decoration: none;
      font-weight: bold;
      margin-bottom: 6px;
      display: block;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left; }
    .sidebar_second .view-noticias .views-row .views-field-title a:hover {
      color: #4C6B80; }
    .sidebar_second .view-noticias .views-row .views-field-field-fecha- {
      font-size: 12px;
      color: #4C6B80;
      border-bottom: 1px solid #E6EBF2;
      padding-bottom: 16px; }
  .sidebar_second .view-noticias .more-link {
    text-align: right; }
    .sidebar_second .view-noticias .more-link a {
      color: #010917;
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline; }
    .sidebar_second .view-noticias .more-link a:after {
      font-family: 'Font Awesome 5 Free';
      content: "\f061";
      font-size: .8rem;
      font-weight: bold;
      color: #303333;
      margin-left: 10px;
      text-decoration: none;
      display: inline-block; }

.degradado {
  display: inline-block;
  background: -webkit-linear-gradient(45deg, #010917, #420075 35%, #007bc4 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative; }

/*SLIDERS SLICK*/
.slick-slider .slick-slide,
.slider-page .slick-slide {
  margin: 0px 25px 0px 0px; }

.slick-slider .slick-slide .views-row,
.slider-page .slick-slide .views-row {
  background-image: url(../images/ide_links.png);
  -webkit-background-size: contain;
  background-size: cover;
  background-repeat: no-repeat;
  height: 210px;
  width: 520px !important; }
  .slick-slider .slick-slide .views-row a,
  .slider-page .slick-slide .views-row a {
    position: relative;
    display: inline-block;
    width: 100%;
    z-index: 1000;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    font-weight: 700;
    padding: 20px 20px;
    transition: all .3s ease; }
    .slick-slider .slick-slide .views-row a:hover,
    .slider-page .slick-slide .views-row a:hover {
      text-decoration: underline; }

.slick-slider .slick-prev,
.slider-page .slick-prev {
  text-align: right;
  top: 230px;
  left: -20px;
  width: 100%;
  height: 20px; }

.slick-slider .slick-next,
.slider-page .slick-next {
  text-align: right;
  top: 230px;
  right: 10px;
  width: 20px;
  height: 20px; }

.slick-slider .slick-prev:before,
.slider-page .slick-prev:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f060";
  font-size: .8rem;
  font-weight: bold;
  color: #4C6B80;
  margin-left: 10px;
  text-decoration: none;
  display: inline-block; }

.slick-slider .slick-next:before,
.slider-page .slick-next:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f061";
  font-size: .8rem;
  font-weight: bold;
  color: #4C6B80;
  margin-left: 10px;
  text-decoration: none;
  display: inline-block; }

.slick-slider .slick-prev,
.slick-slider .slick-next {
  top: -26px !important; }

.block-system-breadcrumb-block .breadcrumb li:first-child .fa-home {
  margin-right: 10px; }

.page-node-48 .post-header {
  padding-bottom: 50px; }

.page-node-48 .view-slider-ultimas-publicaciones .slick-slider .slick-prev, .page-node-48 .view-slider-ultimas-publicaciones .slick-slider .slick-next {
  top: initial !important;
  bottom: -50px !important; }

.breadcrumb-item + .breadcrumb-item::before {
  color: #FFF !important; }

.datepicker .datepicker-switch {
  background-color: #eeeeee;
  color: #333333; }

.datepicker th.next, .datepicker th.prev {
  background-color: #eeeeee;
  color: #333333; }
  .datepicker th.next:hover, .datepicker th.prev:hover {
    background-color: #fff;
    color: #333333; }

.page-node-48 {
  display: none; }

.path-frontpage .ligazons-portada {
  opacity: 0; }

/* xs */
@media (max-width: 767.98px) {
  p {
    font-size: 1rem; }
  li {
    font-size: .8rem;
    margin: 0px 0px 5px 0px !important; }
  .page-title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    letter-spacing: 0em;
    text-align: left; }
  .page-subtitle {
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left; }
  #accordion .card-header a {
    font-size: 1rem; }
  .sidebar_second .view-noticias {
    padding: 0; }
    .sidebar_second .view-noticias .view-header .actualidad-portada {
      font-size: 1.6rem; }
    .sidebar_second .view-noticias .views-row .views-field-title a {
      font-size: .9rem; }
    .sidebar_second .view-noticias .views-row .views-field-field-fecha- {
      font-size: 10px; } }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  color: #007bc4; }

/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  p {
    font-size: .95rem; }
  li {
    font-size: .9rem;
    margin: 0px 0px 8px 0px !important; }
  .page-title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-align: left; }
  .sidebar_second .view-noticias .view-header .actualidad-portada {
    font-size: 1.6rem; }
  .sidebar_second .view-noticias .views-row .views-field-title a {
    font-size: .9rem; }
  .sidebar_second .view-noticias .views-row .views-field-field-fecha- {
    font-size: 10px; } }

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  p {
    font-size: .99rem; }
  li {
    font-size: .95rem;
    margin: 0px 0px 10px 0px !important;
    line-height: 20px; }
  .sidebar_second .view-noticias .view-header .actualidad-portada {
    font-size: 1.7rem; }
  .sidebar_second .view-noticias .views-row .views-field-title a {
    font-size: 1rem; }
  .sidebar_second .view-noticias .views-row .views-field-field-fecha- {
    font-size: 12px; } }
