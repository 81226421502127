/**
 * @file
 * Visual styles for table drag.
 */

tr.drag {
    background-color: #fffff0;
}

tr.drag-previous {
    background-color: #ffd;
}

body div.tabledrag-changed-warning {
    margin-bottom: ($spacer / 2);
}