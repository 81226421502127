/*
Theme Name: Bootstrap 4 Sass
Description: Bootstrap 4 with Sass
*/

// import files
@import "import";

/* GENERAL STYLES
-------------------------------------------------*/

body {
    background: $white;
    font-family: "Xunta Sans" !important;
    font-style: normal;
}

/* EN PÁXINAS QUE NON TEÑEN MOITO CONTIDO O PÉ SUBE E DEIXAR UN OCO DESPOIS QUE NON DEBE SER. 
AXUSTAMOS O PÉ O FONDO DA PÁXINA NO CASO QUE O CONTIDO SEXA POUCO.*/

.dialog-off-canvas-main-canvas{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main{
  &.main-content{
    flex-grow: 1;
  }
}

/* COLORS
--------------------------------------------------*/

.node__content a {
    color: $azul_xunta;
    text-decoration: none;

}

.node__content a:hover {
    color: #0061B2;
    text-decoration: underline;
}
.bg-inverse {
    background: $primary-shade !important;
}

.site-footer {
    background: $accent-shade !important;
}

.menu--main li a {
    color: $primary-shade;
}

.menu--account li a {
    color: $primary-shade;
}

.site-name-slogan a {
    text-decoration: none;
}

.card-group img {
    margin: 0;
    width: 100%;
    height: auto;
}

.card-group .field--type-image {
    margin: 0
}

.slick {
    width: 100%;
}

.slick>div {
    margin: 0 15px;
}

.node__content h2 {
    font-size: 18px;
    margin-bottom: 15px;
    display: block;
    font-weight: bold;
}
/*ANCHO*/
.container-fluid {
  max-width: 1600px;
  padding: 0px 5% !important;
}
/* xs */
@media (max-width: 767.98px) {
  .container-fluid {
    max-width: 100%;
    padding: 0px 15px !important;
  }
}
@media (min-width: 1200px) {
  .container-fluid {
    max-width: 100%;
    margin: 0px;
  }
}

/*Paginador*/
.page-link {
    color: $azul_xunta!important;
    border: none!important;
}
.page-item.active .page-link {
    z-index: 3;
    color: #fff!important;
    background-color: $azul_xunta!important;;
    border: none!important;
}

/*ACORDEON*/

#accordion {
  .card {
    border: none;
  }
  .card-header {
    background-color: $azul_xunta;
    cursor: pointer;
    border: none;
    margin-bottom: 10px;
    a {
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .card-header.collapsed {
    background-color: #E5F2F9;
    a {
      color: $azul_xunta;
    }
  }
  .card-header:active,
  .card-header:hover{
    background-color: $azul_xunta ;
     &:after {
      color: #fff !important;
      border:1px solid  #fff !important;
    }
    a{
      text-decoration: none;
      color: #fff;
    }
  }

  .card-header:after {
    font-family: 'Font Awesome 5 Free';
    content: "\f068";
    float: right;
    font-size: .8rem;
    font-weight: bold;
    color:  #fff;
    margin-left: 10px;
    border:1px solid  #fff;
    padding: 0px 3px;
  }
  .card-header.collapsed:after {
    content: "\f067";
    font-size: .8rem;
    font-weight: bold;
    color: $azul_xunta;
    margin-left: 10px;
    border:1px solid $azul_xunta;
  }
  .card-body{
    padding: 10px;
    .field__item{
      a:before{
        font-family: 'Font Awesome 5 Free';
        content: "\f061";
        font-size: .8rem;
        font-weight: bold;
        color: $azul_xunta;
        margin-left: 10px;
        margin-right: 7px;
      }
      a{
        text-decoration: none;
        margin-bottom: 7px;
        display: block;
        color: $azul_xunta;
      }
      a:hover {
        color: #0061B2;
        text-decoration: underline;
      }
    }
  }
}



/*TITULO*/
.page-title{
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}




/*SUBTITULO*/
.page-subtitle{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
  ul{
    list-style-type: circle;
    li{
      margin-bottom: 10px;
    }
    li::marker{
      font-size: 150%;
      color:$azul_xunta;
    }
  }
}

/*BLOQUE PUBLICACIONES DEL LATERAL*/



.path-node .sidebar_second .view-noticias {
  background-color: transparent;
  padding-top: 0px;
}
.sidebar_second .view-noticias{
    background-color: $background;
    padding: 40px;
    padding-top: 28px;
    .view-header{
      .actualidad-portada {
        display: block;
        margin-bottom: 30px;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .views-row{
      margin-bottom: 20px;
      .views-field-title{
        a{
          color: $main;
          text-decoration: none;
          font-weight: bold;
          margin-bottom: 6px;
          display: block;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }
        a:hover{
          color: #4C6B80;
        }
      }
      .views-field-field-fecha-{
        font-size: 12px;
        color: #4C6B80;
        border-bottom: 1px solid #E6EBF2;
        padding-bottom: 16px;
      }
    }
    .more-link{
      text-align: right;
      a{
        color: #010917;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
      }
      a:after{
        font-family: 'Font Awesome 5 Free';
        content: "\f061";
        font-size: .8rem;
        font-weight: bold;
        color: #303333;
        margin-left: 10px;
        text-decoration: none;
        display: inline-block;
      }
    }
  }

.degradado {
    display: inline-block;
    background: -webkit-linear-gradient(45deg, #010917, #420075 35%, $azul_xunta 75%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    position: relative;
}

// .degradado:before {
//     content: '';
//     display: block;
//     width: 100%;
//     height: 100%;
//     background: linear-gradient(92.58deg, #010917 2.18%, #420075 48.43%, $azul_xunta 98.7%);
//     mix-blend-mode: screen;
//     position: absolute;
//     top: 0;
//     left: 0;
// }


/*SLIDERS SLICK*/
.slick-slider,
.slider-page {
  .slick-slide {
   margin: 0px 25px 0px 0px;
 }
  .slick-slide .views-row{
    background-image: url(../images/ide_links.png);
    -webkit-background-size: contain;
    background-size: cover;
    background-repeat: no-repeat;
    height: 210px;
    width: 520px!important;
    a{
      position: relative;
      display: inline-block;
      width: 100%;
      z-index: 1000;
      color: #fff;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      padding: 20px 20px;
      transition: all .3s ease;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .slick-prev{
    text-align: right;
    top: 230px;
    left: -20px;
    width: 100%;
    height: 20px;
  }

  .slick-next{
    text-align: right;
    top: 230px;
    right: 10px;
    width: 20px;
    height: 20px;
  }

  .slick-prev:before{
    font-family: 'Font Awesome 5 Free';
    content: "\f060";
    font-size: .8rem;
    font-weight: bold;
    color: #4C6B80;
    margin-left: 10px;
    text-decoration: none;
    display: inline-block;
  }
  .slick-next:before {
    font-family: 'Font Awesome 5 Free';
    content: "\f061";
    font-size: .8rem;
    font-weight: bold;
    color: #4C6B80;
    margin-left: 10px;
    text-decoration: none;
    display: inline-block;
  }
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
    top: -26px!important;
}


.block-system-breadcrumb-block .breadcrumb li:first-child .fa-home{
  margin-right: 10px;
}

// SLIDER ACTUALIDADE
.page-node-48{
  .post-header{
    padding-bottom: 50px;
  }
  .view-slider-ultimas-publicaciones{
    .slick-slider{
      .slick-prev, .slick-next{
        top: initial !important;
        bottom: -50px !important;
      }
    } 
  }
}
//BREADCRUMB SEPARADOR
.breadcrumb-item+.breadcrumb-item::before{
  color:#FFF !important;
}

// DATEPICKERS
.datepicker{
  .datepicker-switch{
    background-color:#eeeeee;
    color:#333333;
  }
  th.next, th.prev{
    background-color:#eeeeee;
    color:#333333;
    &:hover{
      background-color:#fff;
    color:#333333;
    }
  }
} 
// OCULTAMOS PÁXINA DE ACTUALIDADE HASTA QUE CARGA SLICK SLIDER - ENTÓN MOSTRAMOS
.page-node-48{
  display: none;
}
.path-frontpage{
  .ligazons-portada{
      opacity: 0;
  }
}



/* xs */
@media (max-width: 767.98px) {
  p {
    font-size: 1rem;
  }
  li {
    font-size: .8rem;
    margin: 0px 0px 5px 0px !important;
  }
  .page-title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 20px;
    letter-spacing: 0em;
    text-align: left;
}
.page-subtitle {
    font-size: .9rem;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
#accordion .card-header a {
    font-size: 1rem;
}
  .sidebar_second {
    .view-noticias {
      padding: 0;
      .view-header {
        .actualidad-portada {
          font-size: 1.6rem;
        }
      }
      .views-row {
        .views-field-title a {
          font-size: .9rem;
        }
        .views-field-field-fecha- {
          font-size: 10px;
        }
      }
    }
  }
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  color: #007bc4;
}



/* md */
@media (min-width: 768px) and (max-width: 991.98px) {
  p {
    font-size: .95rem;
  }
  li {
    font-size: .9rem;
    margin: 0px 0px 8px 0px !important;
  }
  .page-title {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 15px;
    letter-spacing: 0em;
    text-align: left;
}
  .sidebar_second {
    .view-noticias {
      .view-header {
        .actualidad-portada {
          font-size: 1.6rem;
        }
      }
      .views-row {
        .views-field-title a {
          font-size: .9rem;
        }
        .views-field-field-fecha- {
          font-size: 10px;
        }
      }
    }
  }
}


/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  p {
    font-size: .99rem;
  }
  li {
    font-size: .95rem;
    margin: 0px 0px 10px 0px !important;
    line-height: 20px;
  }
  .sidebar_second {
    .view-noticias {
      .view-header {
        .actualidad-portada {
          font-size: 1.7rem;
        }
      }
      .views-row {
        .views-field-title a {
          font-size: 1rem;
        }
        .views-field-field-fecha- {
          font-size: 12px;
        }
      }
    }
  }
}
